<template>
  <carousel-banner />
  <about-container />
  <card-block />
  <fee-structure />
  <!-- <simple-text /> -->
  <!-- <contact-block /> -->
  <line-block />
  <carousel-comments />
  <carousel-gallery />
  <simple-table-text />
  <baner-video />
  <!-- <simple-text-videos /> -->
  <!-- <contact-block /> -->
  <feedback />
  <contacts />
  <contacts-button />
  <footer-main />
</template>

<script>
import CarouselBanner from "@/components/CarouselBanner.vue";
import AboutContainer from "@/components/AboutContainer.vue";
import CardBlock from "@/components/CardBlock.vue";
import FeeStructure from "@/components/FeeStructure.vue";
// import SimpleText from "@/components/SimpleText.vue";
// import ContactBlock from "@/components/ContactBlock.vue";
import LineBlock from "@/components/LineBlock.vue";
import CarouselComments from "@/components/CarouselComments.vue";
import CarouselGallery from "@/components/CarouselGallery.vue";
import SimpleTableText from "@/components/SimpleTableText.vue";
// import SimpleTextVideos from "@/components/SimpleTextVideos.vue";
import BanerVideo from "@/components/BanerVideo.vue";
import FooterMain from "@/components/Footer.vue";
import Contacts from "@/components/Contacts.vue";
import Feedback from "@/components/Feedback.vue";
import ContactsButton from "@/components/ContactsButton.vue";

export default {
  data() {
    return {};
  },
  components: {
    CarouselBanner,
    AboutContainer,
    CardBlock,
    FeeStructure,
    // SimpleText,
    // ContactBlock,
    LineBlock,
    CarouselComments,
    CarouselGallery,
    SimpleTableText,
    // SimpleTextVideos,
    BanerVideo,
    FooterMain,
    Contacts,
    Feedback,
    ContactsButton,
  },
  name: "EnVersion",
};
</script>
