<template>
  <component :is="layout"></component>
  <router-view />
</template>
<script>
import MainLayout from "./layouts/MainLayout";
export default {
  components: {
    MainLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
};
</script>
