<template>
  <div id="carousel-banner-main">
    <div id="carousel-banner">
      <div v-for="(slide, index) in slides" :key="slide" class="slide-wrapper">
        <carousel-banner-slide
          :slide="slide"
          :index="index"
          :visibleSlide="visibleSlide"
          :direction="direction"
        />
      </div>
    </div>
    <div class="button-wrapper">
      <button @click="next" class="next"></button>
      <button @click="prev" class="prev"></button>
    </div>
    <div class="bullet-wrapper">
      <div class="bullet" v-for="(slide, index) in slides" :key="index">
        <div
          class="bullet-body"
          :class="{ active: index === visibleSlide }"
          @click="
            index < visibleSlide ? (direction = 'right') : (direction = 'left');
            visibleSlide = index;
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
import CarouselBannerSlide from "@/components/CarouselBannerSlide.vue";
export default {
  components: { CarouselBannerSlide },
  data() {
    return {
      slides: {},
      visibleSlide: 1,
      direction: "left",
    };
  },
  computed: {
    slidesLen() {
      return this.slides.length;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.slides = datatexten.carouselBanner.slides;
    }
    if (this.$route.name == "FrVersion") {
      this.slides = datatextfr.carouselBanner.slides;
    }
    if (this.$route.name == "AbVersion") {
      this.slides = datatextab.carouselBanner.slides;
    }

    document.getElementById("carousel-banner").style.height =
      (document.documentElement.clientWidth / 1493) * 840 + "px";

    this.$nextTick(function () {
      window.setInterval(() => {
        this.next();
      }, 6000);
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    shiftHeight() {
      document.getElementById("table" + this.visibleSlide).style.height =
        document.getElementById("carousel-banner-main").clientHeight + "px";
      document.getElementById("carousel-banner").style.height =
        document.getElementById("img" + this.visibleSlide).clientHeight + "px";
    },
    handleResize() {
      this.shiftHeight();
    },
    next() {
      if (this.visibleSlide >= this.slidesLen - 1) {
        this.visibleSlide = 0;
      } else {
        this.visibleSlide++;
      }
      this.direction = "left";
    },
    prev() {
      if (this.visibleSlide <= 0) {
        this.visibleSlide = this.slidesLen - 1;
      } else {
        this.visibleSlide--;
      }
      this.direction = "right";
    },
  },
};
</script>
<style lang="scss" scoped>
#carousel-banner-main {
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
#carousel-banner {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  max-height: 960px;
}
.slide-wrapper {
  max-height: 960px;
  overflow: hidden;
}
.button-wrapper {
  display: block;
}
button {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(232, 232, 232, 1);
  top: calc(50% - 15px);
  border-radius: 100px;
  border: none;
  display: block;
  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
  }
}
.next {
  right: 0;
  right: 20px;
  &::after {
    content: ">";
  }
}
.prev {
  left: 0;
  left: 20px;
  &::after {
    content: "<";
  }
}
.bullet-wrapper {
  text-align: center;
  position: absolute;
  z-index: 15;
  padding: 16px 0 0;
  width: 100%;
  font-size: 0;
  bottom: 30px;
}
.bullet {
  display: inline-block;
  padding: 10px 5px;
  cursor: pointer;
}
.bullet-body {
  width: 8px;
  height: 8px;
  background-color: #c7c7c7;
  border-radius: 100px;
  &:hover,
  &.active {
    background-color: #222 !important;
  }
}
</style>
