<template>
  <div id="line-block-main">
    <div id="title-wrapper">
      <div id="title">{{ container.title }}</div>
    </div>
    <div id="content-wrapper">
      <!-- Блок №1 -->
      <div
        class="main-block"
        v-for="(block, index) in container.block"
        :key="index"
      >
        <div class="main-block-container">
          <div
            class="main-block-container-two"
            v-bind:class="{ 'align-right': index % 2 == 1 }"
          >
            <div class="linewrapper">
              <div
                class="line"
                style="width: 2px"
                v-bind:style="{
                  top: index == 0 ? '95px' : '',
                  height:
                    Object.keys(container.block).length - 1 == index
                      ? '100px'
                      : '',
                }"
              ></div>
              <div class="circle" style="border-width: 2px; top: 95px">
                <div class="number">{{ index + 1 }}</div>
              </div>
            </div>
            <div
              v-bind:class="{
                'text-block-left': index % 2 == 0,
                'text-block-right': index % 2 == 1,
              }"
            >
              <img
                class="image-block"
                :src="require('@/assets/' + block.img)"
              />
              <div class="text-block-title" v-html="block.title"></div>
              <div
                class="text-block-description"
                v-html="block.description"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {} };
  },
  created() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.lineBlock;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.lineBlock;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.lineBlock;
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#line-block-main {
  padding-top: 60px;
  padding-bottom: 30px;
  #title-wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    #title {
      margin-bottom: 105px;
      text-align: center;
      font-size: 42px;
      line-height: 1.23;
      font-weight: 600;
    }
  }
  #content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    .align-right {
      float: right !important;
    }
    .main-block {
      position: relative;
      padding-bottom: 30px;
    }
    .main-block-container {
      margin: 0 auto;
      max-width: 860px;
      width: 100%;
      display: table;
    }
    .main-block-container-two {
      width: 50%;
      text-align: center;
    }
    .linewrapper {
      text-align: center;
    }
    .line {
      position: absolute;
      width: 1px;
      top: 0;
      background: #222;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .circle {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      left: 0;
      top: 5px;
      margin: 0 auto;
      background-color: #222;
      border-radius: 100%;
      border: 2px solid #fff;
    }
    .number {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      color: #fff;
      font-size: 16px;
      line-height: 1.35;
      font-weight: 600;
    }
    .text-block-left {
      padding-right: 56px;
      padding-left: 0 !important;
    }
    .text-block-right {
      padding-left: 56px;
      padding-right: 0 !important;
    }
    .image-block {
      width: 100%;
      max-width: 80px;
      display: block;
      margin: 0 auto 14px;
    }
    .text-block-title {
      margin-bottom: 6px;
      margin-top: 10px;
      font-size: 22px;
      line-height: 1.35;
      font-weight: 600;
    }
    .text-block-description {
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 1.55;
      font-weight: 300;
    }
  }
}
</style>
