let DataAb = {
  contactsButton: {
    title: "إكتب لنا",
  },
  footer: {
    copyright: "© 2020 أكاديمية كوزباس الحكومية الزراعية",
    btn1: "جامعة",
    btn2: "البرامج",
    btn3: "قبول",
    btn4: "جهات الاتصال",
    backToTop: "لأعلى",
  },
  contacts: {
    title: "+7 (923) 489 42 32<br>prorector-ur@ksai.ru<br>",
    description:
      '<strong><span style="font-size: 34px;" data-redactor-tag="span"><strong><strong data-redactor-tag="strong"><strong><strong data-redactor-tag="strong"><strong><strong data-redactor-tag="strong"><strong><strong data-redactor-tag="strong"><strong></strong></strong></strong></strong></strong></strong></strong></strong></strong></span></strong><strong> <strong data-redactor-tag="strong">أكاديمية</strong><strong></strong> <strong>كوزباس</strong><strong></strong> <strong>الزراعية</strong><strong></strong> <strong>الحكومية</strong> </strong><br> روسيا , محاقظة كيميروفسكايا كوزباس <br> مدينة كيميروفا شارع ماركوفنسيفا رقم 5 <br>',
  },
  feedback: {
    title: "التقدم بطلب",
    name: " الإسم",
    course: "نوع الدراسة",
    phone: " رقم الهاتف واتس اب, فايبر",
    email: " الإيميل",
    country: " الجنسية",
    btnsubmit: "إرسال الكلب",
    successbox: "شكر! تم إرسال طلبك! سنرد عليك قريبا!",
  },
  mainNavBar: {
    title:
      "<strong>الأكاديمية</strong><strong></strong> <strong>الزراعية</strong><strong></strong> <strong>الحكومية</strong><strong></strong> <strong>قي</strong><strong></strong> <strong>الكوزباس</strong><strong></strong>",
    btn1: "البرامج",
    btn2: "الإلتحاق",
    btn3: "الإتصال",
  },
  lineBlock: {
    title: "كيف تلتحق بالجامعة",
    block: [
      {
        img: "1ed_list.svg",
        title: "تقديم طلب على الإنترنت",
        description:
          "سيتصل بك ممثل لجنة الاختيار للحصول على المشورة. سوف تتلقى رسالة تأكيد في غضون يوم أو يومين.",
      },
      {
        img: "1ed_list.svg",
        title: "توقيع العقد ودفع رسوم التسجيل",
        description:
          "سيرسل لك متخصص لجنة القبول الاتفاقية والفاتورة (بما في ذلك تكلفة الدعوة ورسوم التسجيل). يمكنك دفع فاتورتك عبر الإنترنت، عن طريق التحويل المصرفي أو ويسترن يونيون. يجب عليك توقيع الاتفاقية وإرسال نسخة PDF",
      },
      {
        img: "kideducate_test.svg",
        title: "تلقي دعوة رسمية",
        description:
          "سيتم إصدار دعوة للحصول على تأشيرة طالب وإرسالها إليك خلال 40 يوم عن طريق البريد الإلكتروني ا.",
      },
      {
        img: "9ta_docs.svg",
        title: "التقديم بطلب على تأشيرة",
        description:
          "التقدم بطلب للحصول على تأشيرة في أقرب سفارة روسية. قم بتوثيق وثائقك التعليمية وادفع رسوم التأشيرة في السفارة.",
      },
      {
        img: "touragency_plane.svg",
        title: "خطط لرحلتك",
        description:
          "قم بشراء تذكرة إلى الكوزباس وأخبرنا بوصولك قبل 3 أيام على الأقل من المغادرة.",
      },
      {
        img: "touragency_guide.svg",
        title: "الوصول",
        description:
          "سنبلغ حرس الحدود بوصولك، وسيلتقي بك مندوبنا في المطار وينقلك إلى الجامعة.",
      },
      {
        img: "1ed_teacher.svg",
        title: "التسجيل الجامعي والرسوم الدراسية",
        description:
          "التسجيل في الجامعة وفي بيت الشباب. دفع الرسوم الدراسية والإقامة والتأمين الطبي (100 دولار في السنة) وتمديد التأشيرة (30 دولارًا)",
      },
    ],
  },
  contactBlock: {
    btn1: "الإتصال غبر الواتس اب",
    btn2: "عبر القايبر",
    btn3: "الإيميا و الهاتف",
  },
  simpleText:
    '<strong></strong><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><strong><span style="font-size: 62px;" data-redactor-tag="span"><strong><strong data-redactor-tag="strong"></strong></strong></span></strong></strong></strong></span></strong></strong></span></strong></strong><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><strong><span style="font-size: 62px;" data-redactor-tag="span"><strong><strong data-redactor-tag="strong"></strong></strong></span></strong></strong></strong></span></strong></strong></span></strong></strong><strong></strong><strong></strong><strong></strong> ترحب <strong>أكاديمية</strong><strong></strong> <strong>كوزباس</strong><strong></strong> <strong>الزراعية</strong><strong></strong> <strong>الحكومية</strong><strong></strong> بجميع الطلاب الأجانب. إذا كنت تريد معرفة المزيد عن برامجنا التعليمية أو إجراءات القبول أو لديك أسئلة أخرى، يرجى الاتصال بنا! <br><br><strong> <strong data-redactor-tag="strong">مستشارنا</strong><strong></strong> <strong>متاح</strong><strong></strong> <strong>لمكالمات</strong><strong></strong> <strong>الصوت</strong><strong> /</strong> <strong>الفيديو</strong><strong></strong> <strong>والتواصل</strong><strong></strong> <strong>معك</strong><strong>:</strong> </strong><strong></strong><strong></strong>',
  aboutContainer: {
    title: "<p>الأكاديمية الزراعية الحكومية قي الكوزباس</p>",
    description:
      "<strong></strong> <strong>مرحبًا بكم في أكاديمية كوزباس الزراعية الحكومية - جامعة روسية فريدة من نوعها في مجال ريادة الأعمال! </strong><br> <strong>ريادة</strong><strong></strong> <strong>الأعمال</strong><strong></strong> <br> بالنسبة لنا، ريادة الأعمال هي علم الحياة. يتعلم الطلاب من خلال المشاركة في المشاريع العلمية والعامة والتجارية الحقيقية، ليصبحوا قادة. لا يحصل الخريجون على شهادة الدبلوم فقط، ولكن على مهنة في المشاريع الزراعية الرائدة أو إنشاء أعمالهم الخاصة. هناك مدرسة خاصة بها للمزارعين. <br> <strong>الابتكار</strong> <br> الجامعة الرقمية والتقنيات التعليمية الجديدة والبحث العلمي للحياة الحقيقية. <br> <strong>علم</strong><strong></strong> <strong>البيئة</strong><strong></strong> <br> أنشأت الجامعة فريقًا بيئيًا متطوعًا من الطلاب الذين يشاركون في المشاريع والمسابقات الدولية مع الأقسام في روسيا والخارج. <br> <strong>التصميم</strong> <br> استوديو خاص للفنون الجميلة والتصميم. <br> <strong></strong> <strong>الرياضة</strong><strong></strong> <strong>و</strong><strong></strong> <strong>أسلوب</strong><strong></strong> <strong>حياة</strong><strong></strong> <strong>صحي</strong><strong></strong> <br> تناول طعام صحي في غرفة طعام الأكاديمية.لنادي رياضي، نادي للفروسية الوحيد للطلاب في المنطقة. <br></br>",
  },
  cardBlock: {
    block1: {
      title: "البكالوريوس",
      cards: [
        {
          title: "الهندسة الزراعية",
          img: ["card1.jpg"],
          description:
            '<strong><strong data-redactor-tag="strong">مدة الدراسة</strong><strong>: 4</strong> <strong>سنوات</strong><strong></strong> <br> <strong>الرسوم الدراسية</strong><strong>: 1</strong><strong>،</strong><strong>850</strong> <strong>دولارًا سنويًا</strong><strong></strong> <br><br></strong><br><strong>الملف التجاري الزراعي</strong><strong></strong> <br> احصل على مهنة مهندس زراعي ، يمكنك ، بناءً على دراسة الإمكانات الوراثية للمحاصيل الزراعية ، زيادة الإنتاجية ، وتحسين التكاليف وهيكل المناطق المزروعة ، والتنبؤ بسعر المنتجات المستقبلية من أجل ضمان أقصى ربح مستقر من استخدام الأراضي الزراعية <br>',
        },
        {
          title: "الثروة الحيوانية",
          img: ["card2.jpg"],
          description:
            '<strong></strong><strong>مدة الدراسة</strong><strong>: 4</strong> <strong>سنوات</strong><strong></strong> <br> <strong>الرسوم الدراسية</strong><strong>: 1</strong><strong>،</strong><strong>850</strong> <strong>دولارًا سنويًا</strong><strong></strong> <br><br><strong>التخصصان</strong><strong>"</strong><strong>تكنولوجيا الإنتاج الحيواني</strong><strong>"</strong><strong>،</strong><strong>"</strong><strong>علم دراسة الكلاب</strong><strong>"</strong><strong>،</strong><strong>"</strong><strong>تربية الخيول الرياضية</strong> <br> أخصائي الثروة الحيوانية هو منتج ورائد أعمال في شخص واحد. سوف تتعلم أساسيات الاختيار الجينومي من أجل الحصول على ذرية مقاومة للأمراض المعدية والفيروسية ، بالإضافة إلى زيادة الوظيفة التناسلية للحيوانات. تطوير شروط الحفظ والتغذية على أساس الإمكانات الوراثية لحيوانات المزرعة لضمان أقصى إنتاجية للحيوانات.<strong></strong> <br>',
        },
        {
          title: "الإدارة",
          img: ["card3.jpg"],
          description:
            "<strong></strong><strong>مدة الدراسة</strong><strong>: 4</strong> <strong>سنوات</strong><strong></strong> <br> <strong>الرسوم الدراسية</strong><strong>: 1</strong><strong>،</strong><strong>850</strong><strong> دولارًا سنويًا</strong><strong></strong> <br><br><strong>ملف تعريف مدير بدء التشغيل</strong><strong></strong> <br> مدير بدء التشغيل هو رئيس اتجاه جديد في عمل الشركة أو رجل أعمال ينظم نشاطًا تجاريًا جديدًا.. <br> ستنشئ وتروج مشروعًا جديدًا أو نشاطًا تجاريًا جديدًا ، لتحقيق أهدافك ، وتجمع فريق عمل متناغم من ناس مختلف المهن و الثقافة و الخبرة في فريق واحد. <br",
        },
        {
          title: "أصول التربية",
          img: ["card4.jpg"],
          description:
            '<strong><strong data-redactor-tag="strong">مدة</strong><strong></strong> <strong>الدراسة</strong><strong>: 4</strong> <strong>سنوات</strong><strong></strong> <br> <strong>الرسوم</strong><strong></strong> <strong>الدراسية</strong><strong>: 1</strong><strong>،</strong><strong>850</strong> <strong>دولار</strong><strong> /</strong> <strong>سنة</strong><strong></strong> <br><br></strong><strong>ملف</strong><strong> "</strong><strong>الأحياء</strong><strong>"</strong> <br> المعلم هو مهنة المستقبل. سوف تكون مرشدًا، متخصصًا، رائدًا في العمل في تربية وتعليم الأطفال والشباب. <br>',
        },
      ],
    },
    block2: {
      title: "الماجستير",
      cards: [
        {
          title: "ريادة الأعمال التكنولوجيا",
          img: "card5.jpg",
          description:
            '<strong>مدة</strong><strong></strong> <strong>الدراسة</strong><strong>:</strong> <strong>سنتان</strong><strong></strong> <br> <strong>الرسوم</strong><strong></strong> <strong>الدراسية</strong><strong>: 2400</strong> <strong>دولار</strong><strong></strong> <strong>في</strong><strong></strong> <strong>السنة</strong><strong></strong> <br><br> <strong>م</strong><strong>لف "الهندسة الزراعية" و "علم الحيوان" </strong><br> إنشاء شركة أعمال عالية التقنية لتشجيع الابتكار على أساس البحث الأساسي في مجال إنتاج المحاصيل وتربية الحيوانات <br>',
        },
      ],
    },
    block3: {
      title: "تخصص",
      cards: [
        {
          title: "تكنولوجيا النقل البري",
          img: "card6.jpg",
          description:
            '<strong>مدة الدراسة</strong><strong>: 4.8</strong> <strong>سنوات</strong><strong></strong> <br> <strong>الرسوم الدراسية</strong><strong>: 1</strong><strong>،</strong><strong>850</strong> <strong>دولارًا سنويًا</strong><strong></strong> <br><br> <strong>الملف</strong><strong>"</strong><strong>الوسائل التقنية للزراعة</strong><strong>"</strong> <br> مهندس- مدير ومبتكر. سوف تشارك في تصميم وتصنيع وتشغيل السيارات والجرارات والمركبات البرية الأخرى. <br><strong></strong>',
        },
        {
          title:
            '<strong>دورة</strong><strong></strong> <strong>اللغة</strong><strong></strong> <strong>الروسية</strong><strong></strong> <br> <strong>البرنامج</strong><strong></strong> <strong>التحضيري</strong><strong></strong> <br><br><strong></strong> <strong></strong><span style="font-size: 18px;"><strong>مدة</strong><strong></strong> <strong>الدراسة</strong><strong>: 9</strong> <strong>شهور</strong><strong></strong> <br> <strong>الرسوم</strong><strong></strong> <strong>الدراسية</strong><strong>: 1400</strong> <strong>دو</strong><strong>لا</strong><strong>ر</strong><strong></strong><strong></strong> </span><br>',
          img: "card7.jpg",
          description:
            " هذا البرنامج مخصص لأولئك الذين يرغبون في دخول إحدى الجامعات الروسية والاستعداد للدراسة في إطار برامج البكالوريوس أو الدراسات العليا. يشمل البرنامج: <br> الدورة العامة للغة الروسية. <br> دورة اللغة الروسية للأغراض الأكاديمية <br><ul></ul><ul></ul> ",
        },
      ],
    },
  },
  simpleTextVideos: {
    title: "فيديوهات أخري",
    li: [
      { text: "الهندسة الزراعية ", url: "https://youtu.be/YZGLmdri894" },
      { text: "الإدارة ", url: "https://youtu.be/51GXVjeHsIY" },
      { text: "الثروة الحيوانية      ", url: "https://youtu.be/QLjpQTc8Ptg" },
      { text: "المتطوعين ", url: "https://youtu.be/v6QFFuDRcfY" },
    ],
  },
  simpleTableText: [
    "جو مضياف وظروف مريحة للعيش والدراسة <br>",
    "تستخدم الجامعة التكنولوجيا الرقمية الحديثة على نطاق واسع <br>",
    "مشاريع تجارية حقيقية ومهنة ناجحة في الأعمال الزراعية <br>",
    "<strong>الرياضة، ونمط الحياة الصحي، والبيئة، والثقافة، والقيادة، والأنشطة التطوعية<br></strong>",
    "<strong>بحوث علوم الحياة</strong>",
  ],
  carouselBanner: {
    slides: [
      {
        img: "slider_banner_1.jpg",
        title:
          '<strong><strong data-redactor-tag="strong"> ادرس في روسيا </strong></strong></span><br>نخلق مستقبل الأعمال الزراعية</strong>',
        toptitle: "",
        subtitle: "لغات التعليم: الروسية والإنجليزية",
      },
      {
        img: "slider_banner_2.jpg",
        title:
          '<strong><strong data-redactor-tag="strong"><strong>الأكاديمية</strong><strong></strong> <strong>الزراعية</strong><strong></strong> <strong>الحكومية</strong><strong></strong> <strong>قي</strong><strong></strong> <strong>الكوزباس</strong><strong></strong> </strong></strong>',
        toptitle: "ادرس في روسيا",
        subtitle: "لغات التعليم: الروسية والإنجليزية",
      },
      {
        img: "slider_banner_3.jpg",
        title: "جامعة الأعمال",
        toptitle: "ادرس في روسيا",
        subtitle: "لغات التعليم: الروسية والإنجليزية",
      },
    ],
  },
  carouselGallery: [
    "slider_galery_1.jpg",
    "slider_galery_2.jpg",
    "slider_galery_3.jpg",
    "slider_galery_4.jpg",
    "slider_galery_5.jpg",
    "slider_galery_6.jpg",
    "slider_galery_7.jpg",
    "slider_galery_8.jpg",
    "slider_galery_9.jpg",
    "slider_galery_10.jpg",
    "slider_galery_11.jpg",
    "slider_galery_12.jpg",
    "slider_galery_13.jpg",
    "slider_galery_14.jpg",
    "slider_galery_15.jpg",
    "slider_galery_16.jpg",
    "slider_galery_17.jpg",
    "slider_galery_18.jpg",
    "slider_galery_19.jpg",
    "slider_galery_20.jpg",
    "slider_galery_21.jpg",
    "slider_galery_22.jpg",
    "slider_galery_23.jpg",
    "slider_galery_24.jpg",
    "slider_galery_25.jpg",
    "slider_galery_26.jpg",
    "slider_galery_27.jpg",
  ],
  carouselComments: {
    title: "طلابنا يحبوننا",
    slides: [
      {
        img: "slider_comments_1.jpg",
        description:
          "لقد جئت إلى روسيا من كازاخستان، وتخرجت من أكاديمية كوزباس الزراعية الحكومية، وأنا سعيده جدًا لأنني قضيت سنوات طلابي هنا في كوزباس! لقد أحببت مدينة كيميروفو من النظرة الأولى، وخاصة الشارع الذي يقع فيه الحرم الجامعي. بمرور الوقت، أحببت المدينة أكثر فأكثر، والآن لا أريد أن أتركها. في بداية دراستي، تعرفت على العديد من الأصدقاء الجدد، والتقيت بأصدقاء جدد ما زلت أتواصل معهم. شاركت بنشاط في الأحداث الجامعية، ودرست بنجاح، وفي سنتي الأولى أصبحت عضوًا في نادي طلابي، رقصت في حفلات جامعية. ثم في السنوات التالية، ركزت على البحث والحصول على تعليم إضافي كمترجمة. <br>",
        title: "نيكا سوخاتيرينا من كازاخستان",
      },
      {
        img: "slider_comments_2.jpg",
        description:
          "<strong>مر</strong>حبا بالجميع! اسمي إرازيل أكمولديف. لأكون صريحا، أنا سعيد جدًا بدخول أكاديمية كوزباس الزراعية، لأنني أثبتت أنني هنا. بفضل هذه الجامعة، بدأت اللعب في KVN (نادي المرح والابتكار)، وبدأت العمل كمنظم للأحداث، وأديت الكثير وفازت في المسابقات الإبداعية. أعتقد أن لدي آفاق جيدة. كطالب أجنبي، اعتقدت أنني سأواجه مشاكل في الدراسة، والحياة في بلد جديد، ولكن على مدى السنوات الخمس لم تكن هناك مشكلة واحدة. جميع الروس ودودون ومتعاونون للغاية. لست نادما على أنني اخترت هذه الجامعة، وأرشح الجميع بالذهاب للدراسة في كوزباس <strong></strong><br>",
        title: "إرازيل أكمولديف من كازاخستان",
      },
      {
        img: "slider_comments_3.jpg",
        description:
          ' أنا طالبة في الصف الرابع بكلية "الإدارة و الأعمال الزراعية". <br> خلال العملية الدراسية كاملة كنت أشترك بنشاط في الحياة العامة للأكاديمية. <br> أنا أعتبر من نشطاء نادي الطلاب و في الصيف أعمل في لجنة القبول كموجةة للتخصص. <br> الأكاديمية تقدم إختيار كبير من إتجاهات التعليم و تعليم عالي المستوى و إمكانيات تنمية المهارات و اإمكانيات الشخصية. <br> سنكون سعداء بإنضمامكم لجامعتنا! <br>',
        title: "أي كيس منجوش, روسيا",
      },
      {
        img: "slider_comments_4.jpg",
        description:
          " دراستي، حدثت العديد من الأحداث في حياتي. لقد حققت نجاحات رياضية، في السنة الأولى التي بدأت فيها ألعاب القوى، شاركت في عامين، وشاركت في منافسات المدينة، وكانت هناك انتصارات. في بعض الأحيان كنت مدعوًا للأداء في الرياضات الأخرى، على سبيل المثال، كانت مسابقات الهوكي لا تنسى! مع اللاعبين، قررنا إنشاء فريق KVN الخاص بنا، وسار كل شيء بسرعة كبيرة، وبدأنا على الفور في العمل واللعب وسرعان ما دخلنا في دوري الطلاب KVN في كيميروفو. وصل فريقنا إلى ¼ نهائي الدوري الدولي لـ KVN Asia في كراسنويارسك. عندما أتذكر سنوات طلابي، يمكنني أن أقول إن نجاحي الأول كان مرتبطًا بالعروض في KVN، وبالتالي، أنا الآن قائد فريق كازخستان! هذا هو الطريق الذي انتقلت إليه من مساعد يأخذ الدعائم إلى المسرح، إلى قائد الفريق. نجاحي ليس الحظ فقط، بل هو نتيجة العمل الجاد والإيمان بنفسي بقدراتي! <br>",
        title: "رايف سيريبريكوف من كازاخستان",
      },
      {
        img: "slider_comments_5.jpg",
        description:
          "My name is Vladislav Miroshnikov, I graduated from Kuzbass State" +
          "Agricultural Academy with diploma with high honours. I came from" +
          "Kazakhstan and from the first year I started to be active in the" +
          "academy. Since the 2<sup>nd</sup> year I have been the chairman of the" +
          "trade union organization of students. I was awarded with letters of" +
          "commendation and honorary diplomas of the Administration of the city" +
          "of Kemerovo and the region, which helped me to obtain Russian" +
          'citizenship. I took the 3<sup>rd</sup> place in the city contest "The' +
          'best innovative project". For my personal contribution and development' +
          "of the trade union movement in Kuzbass I was awarded the Anniversary" +
          "Regional Medal.<br />" +
          "And I want to thank the Academy, which helps students to develop" +
          "scientifically and personally, there are several research laboratories" +
          "with unique equipment, where you can conduct research and make" +
          "discoveries. Academy lecturers are doctors of science, professors. I" +
          "think that this aspect makes education qualitative and competitive! I" +
          "wish everyone to enter our Academy, get education and make a" +
          "successful career! <br /><br /><br />",
        title: "Vladislav Miroshnikov, Kazakhstan",
      },
      {
        img: "slider_comments_6.jpg",
        description:
          "I am a fourth year student of the engineering faculty of the field of" +
          'education "Nature management and water use". During my studies at the' +
          "Kuzbass State Agricultural Academy I am engaged in sports, there are" +
          "all conditions for successful combination of active sports and" +
          "studies. <br />In 2020 I became the owner of the belt of the MMA Open" +
          "Cup of the City of Leninsk-Kuznetsky in professional combat - the" +
          "battle for the belt in the weight category 77.1 kg. <br />I came to" +
          "Russia from Tajikistan and I am very happy with the living conditions" +
          "and I recommend to study at our Academy, there are a lot of students" +
          "from my native country here.<br /><br />",
        title: "Idibek Aliyev, Tajikistan",
      },
    ],
  },
};

// eslint-disable-next-line prettier/prettier
export const datatextab = DataAb;
