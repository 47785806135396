<template>
  <div id="contacts-button-main">
    <div>
      <div class="btn">
        <div
          class="btn-wrapper"
          :class="{ background: mainBtnOpen == true }"
          @click="mainBtnOpen = !mainBtnOpen"
        >
          <svg
            :class="{ icon: mainBtnOpen == true }"
            class="btn-open"
            width="35"
            height="32"
            viewBox="0 0 35 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2667 12.6981H23.3667M11.2667 16.4717H23.3667M4.8104 23.5777C2.4311 21.1909 1 18.1215 1 14.7736C1 7.16679 8.38723 1 17.5 1C26.6128 1 34 7.16679 34 14.7736C34 22.3804 26.6128 28.5472 17.5 28.5472C15.6278 28.5472 13.8286 28.2868 12.1511 27.8072L12 27.7925L5.03333 31V23.8219L4.8104 23.5777Z"
              stroke="#ffffff"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            ></path>
          </svg>
          <svg
            :class="{
              icon: mainBtnOpen == false,
            }"
            class="btn-close"
            width="16px"
            height="16px"
            viewBox="0 0 23 23"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" stroke-width="1" fill="#000" fill-rule="evenodd">
              <rect
                transform="translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) "
                x="10.3137085"
                y="-3.6862915"
                width="2"
                height="30"
              ></rect>
              <rect
                transform="translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) "
                x="10.3137085"
                y="-3.6862915"
                width="2"
                height="30"
              ></rect>
            </g>
          </svg>
        </div>
        <div class="popup" :class="{ 'popup-show': mainBtnOpen == true }">
          <div class="popup-container">
            <div class="popup-wrapper">
              <div class="text" v-html="container.title"></div>
              <div class="messenger-wrapper">
                <div class="messenger-block">
                  <a
                    href="https://wa.me/79131380666"
                    class="messenger"
                    rel="nofollow noopener"
                    ><svg
                      width="62"
                      height="62"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="#27D061" fill-rule="nonzero">
                        <path
                          d="M32.367 14.888c-8.275 0-15.004 6.726-15.007 14.993a14.956 14.956 0 0 0 2.294 7.98l.356.567-1.515 5.533 5.677-1.488.548.325a14.979 14.979 0 0 0 7.634 2.09h.006c8.268 0 14.997-6.727 15-14.995a14.9 14.9 0 0 0-4.389-10.608 14.898 14.898 0 0 0-10.604-4.397zm8.417 21.34c-.369 1.052-2.138 2.013-2.989 2.142-.763.116-1.728.164-2.789-.179a25.28 25.28 0 0 1-2.524-.949c-4.444-1.95-7.345-6.502-7.566-6.802-.222-.301-1.809-2.443-1.809-4.661 0-2.218 1.144-3.307 1.55-3.759.406-.451.886-.564 1.181-.564.295 0 .591.003.849.016.272.014.637-.105.996.773.37.903 1.255 3.12 1.366 3.346.11.225.185.488.037.79-.148.3-.222.488-.443.75-.222.264-.465.588-.664.79-.222.224-.453.469-.194.92.258.45 1.147 1.926 2.463 3.12 1.692 1.535 3.119 2.011 3.562 2.237.443.226.701.188.96-.113.258-.3 1.106-1.316 1.401-1.766.295-.45.59-.376.997-.226.406.15 2.583 1.24 3.026 1.466.443.226.738.338.849.526.11.188.11 1.09-.259 2.143z"
                        ></path>
                        <path
                          d="M31 0C13.88 0 0 13.88 0 31c0 17.12 13.88 31 31 31 17.12 0 31-13.88 31-31C62 13.88 48.12 0 31 0zm1.283 47.573h-.007c-3 0-5.948-.75-8.566-2.171l-9.502 2.48 2.543-9.243a17.735 17.735 0 0 1-2.392-8.918c.003-9.836 8.044-17.838 17.924-17.838 4.795.002 9.296 1.86 12.68 5.232 3.384 3.371 5.247 7.853 5.245 12.62-.004 9.836-8.046 17.838-17.925 17.838z"
                        ></path>
                      </g></svg
                  ></a>
                  <div class="messenger-descr">WhatsApp</div>
                </div>
                <div class="messenger-block">
                  <a
                    href="https://t.me/+79131380666"
                    class="messenger"
                    rel="nofollow noopener"
                    ><svg
                      style="color: rgb(42, 171, 238)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="62"
                      height="62"
                      fill="currentColor"
                      class="bi bi-telegram"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
                        fill="#2aabee"
                      ></path></svg
                  ></a>
                  <div class="messenger-descr">Telegram</div>
                </div>
                <div class="messenger-block">
                  <a
                    href="mailto:inter@ksai.ru"
                    class="messenger"
                    rel="nofollow noopener"
                    ><svg
                      width="62"
                      height="62"
                      fill="#168de2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 98.6 98.6"
                    >
                      <path
                        d="M49.2 0C22 0-.1 22.1-.1 49.3S22 98.6 49.2 98.6s49.3-22.1 49.3-49.3S76.5 0 49.2 0zm27.2 29.5L49.2 53.6 22 29.5h54.4zm-58.1 3.4l18.6 16.4-18.6 16.4V32.9zM22 69.1l18.6-16.4 6.9 6.2c.5.4 1.1.6 1.7.6s1.2-.2 1.7-.6l6.9-6.2 18.6 16.4H22zm58.1-3.4L61.6 49.3l18.6-16.4v32.8z"
                      ></path></svg
                  ></a>
                  <div class="messenger-descr">Mail</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { mainBtnOpen: false, container: {} };
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.contactsButton;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.contactsButton;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.contactsButton;
    }
  },
};
</script>
<style lang="scss" scoped>
#contacts-button-main {
  opacity: 1;
  .btn {
    bottom: 65px;
    right: 65px;
    cursor: pointer;
    position: fixed;
    z-index: 1000;
  }
  .background {
    background: #fff !important;
  }
  .icon {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
  }
  .btn-wrapper {
    background: #0088cc;
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
    transition: all 0.2s linear;
    overflow: hidden;
  }
  .btn-open {
    fill: white;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    top: 1px;
  }
  .btn-close {
    fill: white;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    top: 0px;
  }
  .popup {
    display: none;
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.2s;
    transition: opacity ease-in-out 0.2s;
    position: relative;
    z-index: 10000;
  }
  .popup-show {
    display: block;
    opacity: 1;
  }
  .popup-container {
    margin: 0;
    top: initial;
    left: initial;
    bottom: 145px;
    right: 65px;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%);
    background: #fff;
    position: fixed;
    z-index: 1;
    cursor: default;
    max-width: 300px;
  }
  .popup-wrapper {
    padding: 30px 20px 20px;
    border-radius: 5px;
    max-height: 65vh;
    text-align: center;
  }
  .text {
    padding-bottom: 30px !important;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 22px;
    line-height: 1.35;
    font-weight: 600;
  }
  .messenger-block {
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 20px;
  }
  .messenger {
    text-decoration: none;
    display: block;
    height: 62px;
    width: 62px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 50%;
    line-height: 1.35;
    font-weight: 600;
  }
  .messenger-descr {
    padding-top: 8px;
    font-size: 12px;
    opacity: 0.8;
    line-height: 1.55;
    font-weight: 300;
  }
}
</style>
