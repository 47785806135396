<template>
  <div id="contacts-main">
    <div id="contacts-container">
      <div id="container-center">
        <div id="title-center">
          <div class="title-text" v-html="container.title"></div>
        </div>
        <div id="imgwrapper"><img src="@/assets/logoksai.png" /></div>
        <div id="text-bottom" v-html="container.description"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return {};
  },
  created() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.contacts;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.contacts;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.contacts;
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#contacts-main {
  padding-top: 0px;
  padding-bottom: 30px;
  background-color: #ededed;
  #contacts-container {
    max-width: 1200px;
    display: table;
    margin: 0 auto;
    #container-center {
      max-width: 560px;
      width: 100%;
      text-align: center;
      #title-center {
        margin-bottom: 70px;
        line-height: 1.23;
        font-weight: 600;
        text-align: center;
        .title-text {
          font-size: 36px;
        }
      }
      #imgwrapper {
        width: 160px;
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
        img {
          width: inherit;
        }
      }
      #text-bottom {
        margin-top: 45px;
        font-size: 18px;
        line-height: 1.55;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}
</style>
