<template>
  <div id="feedback-main">
    <div id="title-main">
      <div id="title-wrapper" v-html="container.title"></div>
    </div>
    <div id="t-container">
      <form
        id="feedback-form"
        @submit.prevent="checkForm"
        action="/enVersion"
        method="post"
        novalidate="true"
      >
        <div id="inputbox" v-if="!formsuccess">
          <div class="input-block">
            <div class="input-group">
              <input
                id="name"
                v-model="name"
                :placeholder="container.name"
                :class="{ 'error-border': nameerror }"
              />
              <div v-if="nameerror" class="input-error">
                {{ errortextname }}
              </div>
            </div>
            <div class="input-group">
              <input
                id="course"
                v-model="course"
                :placeholder="container.course"
                :class="{ 'error-border': courseerror }"
              />
              <div v-if="courseerror" class="input-error">{{ errortext }}</div>
            </div>
            <div class="input-group">
              <input
                id="phone"
                v-model="phone"
                :placeholder="container.phone"
                :class="{ 'error-border': phoneerror }"
              />
              <div v-if="phoneerror" class="input-error">
                {{ errortextphone }}
              </div>
            </div>
            <div class="input-group">
              <input
                id="email"
                v-model="email"
                :placeholder="container.email"
                type="email"
                :class="{ 'error-border': emailerror }"
              />
              <div v-if="emailerror" class="input-error">
                {{ errortextemail }}
              </div>
            </div>
            <div class="input-group">
              <input
                id="country"
                v-model="country"
                :placeholder="container.country"
                :class="{ 'error-border': countryerror }"
              />
              <div v-if="countryerror" class="input-error">
                {{ errortext }}
              </div>
            </div>
          </div>
        </div>
        <div class="error-block" v-if="errors.length && !formsuccess">
          <div class="error-block-wrapper">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </div>
        </div>
        <div class="form-submit" v-if="!formsuccess">
          <button class="button-submit" @click="checkForm">
            {{ container.btnsubmit }}
          </button>
        </div>
        <div class="successbox" v-if="formsuccess">
          {{ container.successbox }}
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return {
      container: {},
      formsuccess: false,
      errors: [],
      name: null,
      nameerror: false,
      course: null,
      courseerror: false,
      phone: null,
      phoneerror: false,
      country: null,
      countryerror: false,
      email: null,
      emailerror: false,
      errortextname: "",
      errortextphone: "",
      errortext: "Обязательное поле",
      errortextemail: "Укажите, пожалуйста, корректный email",
    };
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.feedback;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.feedback;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.feedback;
    }
  },
  methods: {
    checkForm: function (e) {
      this.errors = [];
      if (!this.name) {
        this.nameerror = true;
        this.errortextname = "Обязательное поле";
      } else if (this.validName(this.name)) {
        this.errortextname = "Укажите, пожалуйста, имя";
        this.errors.push("Укажите, пожалуйста, имя");
        this.nameerror = true;
      } else {
        this.nameerror = false;
      }
      if (!this.course) {
        this.courseerror = true;
      } else {
        this.courseerror = false;
      }
      if (!this.phone) {
        this.phoneerror = true;
        this.errortextphone = "Обязательное поле";
      } else if (this.validPhone(this.phone)) {
        this.errortextphone = "Укажите, пожалуйста, корректный номер телефона";
        this.errors.push("Укажите, пожалуйста, корректный номер телефона");
        this.phoneerror = true;
      } else {
        this.phoneerror = false;
      }
      if (!this.country) {
        this.countryerror = true;
      } else {
        this.countryerror = false;
      }

      if (!this.email) {
        this.emailerror = false;
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Укажите, пожалуйста, корректный email");
        this.emailerror = true;
      }

      if (
        this.nameerror ||
        this.courseerror ||
        this.phoneerror ||
        this.countryerror
      ) {
        this.errors.push("Пожалуйста, заполните все обязательные поля");
      }

      if (this.errors.length == 0) {
        axios
          .post("https://event.ksai.ru/feedback/send", {
            name: this.name,
            course: this.course,
            phone: this.phone,
            email: this.email,
            nationality: this.country,
          })
          .then((res) => {
            if (res.data == "succefull") {
              this.formsuccess = true;
            }
          });
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validName: function (name) {
      var re = /[^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$]/;
      return re.test(name);
    },
    validPhone: function (phone) {
      var re = /[^0-9]/;
      return re.test(phone);
    },
  },
};
</script>
<style lang="scss" scoped>
#feedback-main {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #ededed;
  #title-main {
    max-width: 1200px;
    display: block;
    margin: 0 auto;
    margin-bottom: 75px;
    #title-wrapper {
      width: 100%;
      text-align: center;
      font-size: 42px;
      line-height: 1.23;
      font-weight: 600;
    }
  }
  #t-container {
    max-width: 1200px;
    margin: 0 auto;
    #feedback-form {
      max-width: 760px;
      margin: 0 auto;

      #inputbox {
        .input-block {
          margin-bottom: 25px;
        }
        .input-group {
          margin-bottom: 25px;
        }
        input {
          color: #000000;
          border: 1px solid #8c8c8c;
          margin: 0;
          font-family: "Roboto", Arial, sans-serif;
          font-size: 100%;
          height: 60px;
          padding: 0 20px;
          font-size: 16px;
          line-height: 1.33;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          outline: none;
          -webkit-appearance: none;
          border-radius: 0;
          outline: none;
          padding-left: 0 !important;
          padding-right: 0 !important;
          border-top: 0 !important;
          border-right: 0 !important;
          border-left: 0 !important;
          background-color: transparent !important;
          border-radius: 0 !important;
          &:focus::placeholder {
            color: transparent;
          }
        }
        .input-error {
          color: red;
          font-family: "Roboto", Arial, sans-serif;
          font-size: 13px;
          margin-top: 5px;
        }
        .error-border {
          border: 1px solid red !important;
          padding-left: 10px !important;
        }
      }
      .error-block {
        background: #f95d51;
        padding: 10px;
        text-align: center;
        margin-bottom: 20px;
        .error-block-wrapper {
          color: #fff;
          box-sizing: border-box;
          padding: 0 10px 10px 10px;
          font-size: 20px;
          line-height: 1.55;
          font-weight: 300;
          ul {
            list-style-type: none;
            margin: 0;
            li {
              padding-top: 10px;
            }
          }
        }
      }
      .successbox {
        text-align: center;
        background: #62c584;
        color: #fff;
        padding: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1.55;
        font-weight: 300;
      }
      .form-submit {
        display: block;
        text-align: center;
        height: 100%;
        margin-top: 30px;
        margin-bottom: 10px;
        .button-submit {
          color: #ffffff;
          background-color: #3279e3;
          font-family: "Roboto", Arial, sans-serif;
          text-align: center;
          height: 60px;
          border: 0 none;
          font-size: 16px;
          padding-left: 60px;
          padding-right: 60px;
          -webkit-appearance: none;
          font-weight: 700;
          white-space: nowrap;
          background-image: none;
          cursor: pointer;
          margin: 0;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          outline: none;
          position: relative;
        }
      }
    }
  }
}
</style>
