<template>
  <transition :name="direction" mode="in-out">
    <div
      :id="'slide' + index"
      class="carousel-slide"
      v-show="visibleSlide === index"
    >
      <img :src="require('@/assets/' + slide.img)" class="img-circle" />
      <div class="description" v-html="slide.description"></div>
      <div class="title">
        <strong v-html="slide.title"></strong>
      </div></div
  ></transition>
</template>
<script>
export default {
  props: ["slide", "index", "visibleSlide", "direction"],
  data() {
    return {};
  },
  computed: {
    // slideheightcalc() {
    //   return document.getElementById("slide0").clientHeight;
    // },
  },
  mounted() {
    // this.$nextTick(() => {
    //    this.$emit("slideheight", this.slideheightcalc);
    // });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.carousel-slide {
  // position: absolute;
  left: 0;
  top: 0;
}
.img-circle {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  display: block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.description {
  margin-top: 40px;
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
  text-align: center;
  margin-left: 200px;
  margin-right: 200px;
}
.title {
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.35;
  text-align: center;
}
.left-enter-active {
  top: 0;
  display: block !important;
  left: 100%;
  animation: leftInAnimation 0.4s ease-in-out;
}
.left-leave-active {
  position: absolute;
  display: block !important;
  top: 0;
  left: 0;
  animation: leftOutAnimation 0.4s ease-in-out;
}
@keyframes leftInAnimation {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes leftOutAnimation {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-100%);
  }
}
.right-enter-active {
  position: absolute;
  top: 0;
  display: block !important;
  left: 0;
  animation: rightInAnimation 0.4s ease-in-out;
}
.right-leave-active {
  display: block !important;
  left: -100%;
  top: 0;
  animation: rightOutAnimation 0.4s ease-in-out;
}
@keyframes rightInAnimation {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes rightOutAnimation {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(+100%);
  }
}
</style>
