<template>
  <div id="navbar">
    <div id="main-container">
      <div id="wrapper-left">
        <ul>
          <li>
            <a @click="scrolltoelem('main-container-card-block')">{{
              getcurrentpath === "EnVersion"
                ? datatexten.mainNavBar.btn1
                : getcurrentpath === "FrVersion"
                ? datatextfr.mainNavBar.btn1
                : datatextab.mainNavBar.btn1
            }}</a>
          </li>
          <li>
            <a @click="scrolltoelem('main-container-feestructure-block')">{{
              getcurrentpath === "EnVersion"
                ? datatexten.mainNavBar.btn4
                : getcurrentpath === "FrVersion"
                ? datatextfr.mainNavBar.btn4
                : datatextab.mainNavBar.btn4
            }}</a>
          </li>
          <li>
            <a @click="scrolltoelem('line-block-main')">{{
              getcurrentpath === "EnVersion"
                ? datatexten.mainNavBar.btn2
                : getcurrentpath === "FrVersion"
                ? datatextfr.mainNavBar.btn2
                : datatextab.mainNavBar.btn2
            }}</a>
          </li>
          <li>
            <a @click="scrolltoelem('contacts-main')">{{
              getcurrentpath === "EnVersion"
                ? datatexten.mainNavBar.btn3
                : getcurrentpath === "FrVersion"
                ? datatextfr.mainNavBar.btn3
                : datatextab.mainNavBar.btn3
            }}</a>
          </li>
        </ul>
      </div>
      <div id="wrapper-center">
        <div
          id="text-logo"
          v-html="datatexten.mainNavBar.title"
          v-if="getcurrentpath == 'EnVersion'"
        ></div>
        <div
          id="text-logo"
          v-html="datatextfr.mainNavBar.title"
          v-if="getcurrentpath == 'FrVersion'"
        ></div>
        <div
          id="text-logo"
          v-html="datatextab.mainNavBar.title"
          v-if="getcurrentpath == 'AbVersion'"
        ></div>
      </div>
      <div id="wrapper-right">
        <div id="text-phone">8 913 138 06 66</div>
        <!-- <div id="text-lang">
          <router-link to="/EnVersion" v-if="getcurrentpath != 'EnVersion'"
            >EN</router-link
          >
          <router-link to="/FrVersion" v-if="getcurrentpath != 'FrVersion'"
            >FR</router-link
          >
          <router-link to="/AbVersion" v-if="getcurrentpath != 'AbVersion'"
            >عربي</router-link
          >
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return {
      container: "",
      datatexten: datatexten,
      datatextfr: datatextfr,
      datatextab: datatextab,
    };
  },
  computed: {
    getcurrentpath() {
      return this.$route.name;
    },
  },
  created() {},
  mounted() {},
  update() {},
  methods: {
    scrolltoelem(elem) {
      document.getElementById(elem).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
#navbar {
  background-color: rgba(8, 7, 7, 0.95);
  width: 100%;
  color: #ffffff;
  white-space: nowrap;

  #main-container {
    max-width: 1200px;
    margin: 0 auto;
    display: table;
    position: relative;
    font-weight: 600;
    height: 90px;
  }
}

#wrapper-left {
  padding-left: 20px;
  padding-right: 20px;
  // width: 33%;
  display: table-cell;
  vertical-align: middle;
  // min-width: 315px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 16px;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      margin: 0;
      white-space: nowrap;
    }
    li:first-child {
      padding-left: 0px;
    }
    li:last-child {
      padding-right: 0px;
    }
  }
  a {
    cursor: pointer;
    padding-bottom: 2px;
  }
  a:hover {
    border-bottom: 1px solid #ffffff;
    -webkit-box-shadow: inset 0px -1px 0px 0px #ffffff;
    -moz-box-shadow: inset 0px -1px 0px 0px #ffffff;
    box-shadow: inset 0px -1px 0px 0px #ffffff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
#wrapper-center {
  font-size: 18px;
  text-align: center;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}
#wrapper-right {
  padding-right: 20px;
  text-align: right;
  width: 33%;
  padding-left: 20px;
  display: inline-table;
  height: 100%;
  vertical-align: middle;
  float: right;
  #text-phone {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
  #text-lang {
    padding-left: 30px;
    display: table-cell;
    vertical-align: middle;
  }
  a {
    color: #ffffff;
    padding: 0 10px;
    font-weight: 600;
    text-decoration: none;

    // &.router-link-exact-active {
    // }
  }
}
</style>
