<template>
  <div id="baner-video-main">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/_-0s__lT5JI"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#baner-video-main {
  position: relative;
  overflow: hidden;
  width: 720px;
  padding-top: 480px;
  margin: 0 auto;
  margin-bottom: 75px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
