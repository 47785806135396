<template>
  <transition :name="direction" mode="in-out">
    <div :id="'slide' + index" class="carousel-slide">
      <img
        :src="require('@/assets/' + slide)"
        :class="{ active: index === visibleSlide }"
        class="img"
      /></div
  ></transition>
</template>
<script>
export default {
  props: ["slide", "index", "visibleSlide", "direction"],
  data() {
    return {};
  },
  computed: {
    // slideheightcalc() {
    //   return document.getElementById("slide0").clientHeight;
    // },
  },
  mounted() {
    // this.$nextTick(() => {
    //    this.$emit("slideheight", this.slideheightcalc);
    // });
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.carousel-slide {
  // position: absolute;
  left: 0;
  top: 0;
}
.active {
  opacity: 1 !important;
  filter: blur(0px) !important;
}
.img {
  width: 900px;
  margin: 0 auto;
  display: block;
  opacity: 0.7;
  filter: blur(2px);
  float: left;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}
.left-enter-active {
  top: 0;
  display: block !important;
  left: 100%;
  animation: leftInAnimation 0.4s ease-in-out;
}
.left-leave-active {
  position: absolute;
  display: block !important;
  top: 0;
  left: 0;
  animation: leftOutAnimation 0.4s ease-in-out;
}
@keyframes leftInAnimation {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes leftOutAnimation {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-100%);
  }
}
.right-enter-active {
  position: absolute;
  top: 0;
  display: block !important;
  left: 0;
  animation: rightInAnimation 0.4s ease-in-out;
}
.right-leave-active {
  display: block !important;
  left: -100%;
  top: 0;
  animation: rightOutAnimation 0.4s ease-in-out;
}
@keyframes rightInAnimation {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes rightOutAnimation {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(+100%);
  }
}
</style>
