let DataFr = {
  contactsButton: {
    title: "Écrivez-nous!",
  },
  footer: {
    copyright: "© 2020 Académie agricole d'État de Kuzbass",
    btn1: "Université",
    btn2: "Programmes",
    btn3: "Entrée",
    btn4: "Contacts",
    backToTop: "Vers le haut",
  },
  contacts: {
    title: "+7 (923) 489 42 32<br>prorector-ur@ksai.ru<br>",
    description:
      '<strong><span style="font-size: 34px;" data-redactor-tag="span"><strong><strong data-redactor-tag="strong"><strong><strong data-redactor-tag="strong"><strong><strong data-redactor-tag="strong"><strong><strong data-redactor-tag="strong"><strong></strong></strong></strong></strong></strong></strong></strong></strong></strong></span></strong><strong><span data-redactor-tag="span" style="font-size: 32px;"><strong>Académie agricole d\'État de Kuzbass</strong></span></strong><br> Russie, région de Kemerovo - Kuzbass <br> G. Kemerovo, saint Marcovtseva, 5 <br></br>',
  },
  feedback: {
    title: "Soumettre une candidature",
    name: "Votre nom",
    course: "Domaine détudes",
    phone: "Votre numéro de téléphone mobile (quelle application, Viber)",
    email: "Votre email",
    country: "Votre citoyenneté",
    btnsubmit: "Envoyer une demande",
    successbox:
      "Merci! Votre demande a été envoyée! Nous vous répondrons bientôt!",
  },
  mainNavBar: {
    title: "État de Kuzbass Académie agricole",
    btn1: "Programmes",
    btn2: "Postuler",
    btn3: "Contact",
  },
  lineBlock: {
    title: "Comment entrer à l'université",
    block: [
      {
        img: "1ed_list.svg",
        title: "Postuler en ligne",
        description:
          "Un représentant du comité de sélection vous contactera pour des conseils. Vous recevrez une lettre de confirmation dans 1-2 jours.",
      },
      {
        img: "1ed_list.svg",
        title: "Signez le contrat et payez les frais d'inscription",
        description:
          "Le spécialiste du comité d'admission vous fera parvenir l'accord et la facture (y compris le coût de l'invitation et les frais d'inscription). Vous pouvez payer votre facture en ligne, par virement bancaire ou Western Union. Vous devez signer l'accord et nous envoyer une copie au format PDF.",
      },
      {
        img: "kideducate_test.svg",
        title: "Recevez une invitation officielle",
        description:
          "Recevez une invitation officielle Une invitation pour un visa étudiant sera émise et vous sera envoyée au format PDF par e-mail. Temps d'inscription: 40 jours.",
      },
      {
        img: "9ta_docs.svg",
        title: "Demander un visa",
        description:
          "Demandez un visa à l'ambassade de Russie la plus proche. Légalisez vos documents scolaires et payez les frais de visa à l'ambassade.",
      },
      {
        img: "touragency_plane.svg",
        title: "Planifiez votre voyage",
        description:
          "Achetez un billet pour Kuzbass et informez-nous de votre arrivée au moins 3 jours avant le départ",
      },
      {
        img: "touragency_guide.svg",
        title: "Arrivée",
        description:
          "Nous informerons le garde-frontière de votre arrivée et notre représentant vous attendra à l'aéroport et vous livrera à l'université.",
      },
      {
        img: "1ed_teacher.svg",
        title: "Inscription à l'université et frais de scolarité",
        description:
          "Inscrivez-vous à l'université et à l'auberge. Payer les frais de scolarité, l'hébergement, l'assurance médicale (100 $ par an), l'extension du visa (30 $)",
      },
    ],
  },
  contactBlock: {
    btn1: "Ecrivez sur What's App",
    btn2: "Ecrivez sur Viber",
    btn3: "Email / Тelephone",
  },
  simpleText:
    "<strong></strong><strong>L'Académie agricole d'État de Kuzbass</strong> accueille tous les étudiants étrangers. Si vous souhaitez en savoir plus sur nos programmes éducatifs, les procédures d'admission ou si vous avez d'autres questions, contactez-nous!<br><br> <strong>Notre consultant est disponible pour les appels vocaux / vidéo et la communication avec vous:</strong> <br><strong></strong>",
  aboutContainer: {
    title:
      "<p>Acad</strong><strong>é</strong><strong>mie</strong> <strong></strong> <strong>agricole</strong> <strong></strong> <strong>d</strong><strong>'É</strong><strong>tat</strong> <strong></strong> <strong>de</strong> <strong></strong> <strong>Kuzbass</strong><strong></strong> </strong></p>",
    description:
      "<strong></strong><strong>Bienvenue à la Kuzbass State Agricultural Academy - une université russe unique de type entrepreneurial!</strong><br> <strong>ENTREPRENEURIAT</strong><br> Pour nous, l'entrepreneuriat est la science de la vie. Les étudiants apprennent en participant à de vrais projets scientifiques, publics et commerciaux, devenant des leaders. Les diplômés reçoivent non seulement un diplôme, mais une carrière dans des entreprises agricoles de premier plan ou créent leur propre entreprise. Propre école d'agriculteurs <br> <strong>INNOVATION</strong><br> Université numérique, nouvelles technologies éducatives, recherche scientifique pour la vie réelle.<br> <strong>ÉCOLOGIE</strong><br> L'université a créé une équipe volontaire d'étudiants en environnement qui participent à des projets et concours internationaux avec des succursales en Russie et à l'étranger.<br> <strong>DESIGN</strong><br> Propre studio d'art et de design.<br> <strong>Mode de vie sain&amp; SPORTS</strong><br> Repas sain dans la salle à manger. Club de sport, le seul club équestre étudiant de la région.<strong></strong> <br>",
  },
  cardBlock: {
    block1: {
      title: "Baccalaureat",
      cards: [
        {
          title: "Agronomie",
          img: ["card1.jpg"],
          description:
            '<strong>Duration: 4 years<br>Tuition fee: $1,850 / year</strong><strong><br></strong><br>Educational program specialization "Agribusiness" <br><br>Get a profession of agronomist! Duringthe study of the genetic potential of crops you will be able toimprove yields, optimize costs and structure of areas under crops,to predict the price of future products in order to consistentlyensure maximum profit from the use of agricultural land. <br>',
        },
        {
          title: "Bétail",
          img: ["card2.jpg"],
          description:
            '<strong></strong><strong>Duration: 4 years<br>Tuition fee: $1,850 / year</strong><br><br>Educational program specialization "Technology of livestock production", "Cynology", "Sports horse husbandry" <br><br>A livestock expert is a producer and an entrepreneur. You will learn the basics of genomic selection in order to produce offspring resistant to infectious and viral diseases, as well as improve the reproductive function of animals. Development of breeding and feeding conditions based on the genetic potential of farm animals in order to maximize productivity of animals. <br>',
        },
        {
          title: "Мanagement",
          img: ["card3.jpg"],
          description:
            '<strong></strong><strong>Duration: 4 years<br>Tuition fee: $1,850 / year</strong><br><br>Educational program specialization "Startup Manager" <br><br> Startup - manager is a manager of a new direction in a companys business or an entrepreneur who establishes a new business. <br>After this program you will be able to create and promote a new project or business, unite in a well-coordinated "single organism" people with completely different professional experience and culture. <br>',
        },
        {
          title: "La pédagogie",
          img: ["card4.jpg"],
          description:
            '<strong>Duration: 4 years <br>Tuition fee: $1,850 / year</strong><strong><br><br></strong>Educational program specialization "Biology" <br><br> A teacher is a profession of the future. After this program you will be a mentor, a specialist, whose aim is to educate children and young people. <br>',
        },
      ],
    },
    block2: {
      title: "Маster",
      cards: [
        {
          title: "Entrepreneuriat technologique",
          img: "card5.jpg",
          description:
            '<strong data-v-6130c4dd="">Duration: 2 years<br data-v-6130c4dd="">Tuition fee: $2,400 / year</strong><br data-v-6130c4dd=""><br data-v-6130c4dd="">Educational program specializations "Agronomy" and "Zootechnics" <br data-v-6130c4dd=""><br data-v-6130c4dd=""> Creation of a high-tech business in order to promote innovation based on fundamental research in crop and livestock production. <br data-v-6130c4dd="">',
        },
      ],
    },
    block3: {
      title: "Specialiste",
      cards: [
        {
          title: "Technologie de transport terrestre",
          img: "card6.jpg",
          description:
            '<strong>Duration: 4,8 years<br>Tuition fee: $1,850 / year</strong><br><br>Educational program specialization "Technical facilities of AIC" <br><br> Engineer is a manager and an innovator. During training you will be involved in the design, production and operation of cars, trucks and other land vehicles. <br><strong></strong>',
        },
        {
          title:
            '<strong>Cours de russe</strong> <strong>programme</strong><br> <br><span style="font-size: 18px;"><strong>Durée de l\'étude: 9 mois</strong> <br> <strong>Frais de scolarité: 1400 $</strong><strong></strong> </span><br>',
          img: "card7.jpg",
          description:
            "his programme is designed for those who wish to enter one of Russian University or to prepare themselves for undergraduate or postgraduate studies. The programme includes: <br> <ul> <li> General Russian Language Course; </li> <li> Russian for Academic Purposes;</li></ul> ",
        },
      ],
    },
  },
  simpleTextVideos: {
    title: "Autres vidéos:",
    li: [
      { text: "Agronomie ", url: "https://youtu.be/YZGLmdri894" },
      { text: "Management ", url: "https://youtu.be/51GXVjeHsIY" },
      { text: "Zootechnie ", url: "https://youtu.be/QLjpQTc8Ptg" },
      { text: "Bénévoles ", url: "https://youtu.be/v6QFFuDRcfY" },
    ],
  },
  simpleTableText: [
    "Atmosphère accueillante et conditions confortables pour vivre, étudier <br>",
    "L'université fait un usage intensif de la technologie numérique moderne <br>",
    "De vrais projets d'entreprise et une carrière réussie dans l'agroalimentaire<br>",
    "<strong>Sport, mode de vie sain, écologie, culture, leadership, activités bénévoles <br></strong>",
    "<strong>Recherche en sciences de la vie</strong>",
  ],
  carouselBanner: {
    slides: [
      {
        img: "slider_banner_1.jpg",
        title: "Créer l'avenir de l'agro-industrie",
        toptitle: "Etudie en Russie!",
        subtitle: "Langues d'apprentissage: russe, anglais",
      },
      {
        img: "slider_banner_2.jpg",
        title: "Académie agricole d'État de Kuzbass",
        toptitle: "Etudie en Russie!",
        subtitle: "Langues d'apprentissage: russe, anglais",
      },
      {
        img: "slider_banner_3.jpg",
        title: "Université type entrepreneurial",
        toptitle: "Etudie en Russie!",
        subtitle: "Langues d'apprentissage: russe, anglais",
      },
    ],
  },
  carouselGallery: [
    "slider_galery_1.jpg",
    "slider_galery_2.jpg",
    "slider_galery_3.jpg",
    "slider_galery_4.jpg",
    "slider_galery_5.jpg",
    "slider_galery_6.jpg",
    "slider_galery_7.jpg",
    "slider_galery_8.jpg",
    "slider_galery_9.jpg",
    "slider_galery_10.jpg",
    "slider_galery_11.jpg",
    "slider_galery_12.jpg",
    "slider_galery_13.jpg",
    "slider_galery_14.jpg",
    "slider_galery_15.jpg",
    "slider_galery_16.jpg",
    "slider_galery_17.jpg",
    "slider_galery_18.jpg",
    "slider_galery_19.jpg",
    "slider_galery_20.jpg",
    "slider_galery_21.jpg",
    "slider_galery_22.jpg",
    "slider_galery_23.jpg",
    "slider_galery_24.jpg",
    "slider_galery_25.jpg",
    "slider_galery_26.jpg",
    "slider_galery_27.jpg",
  ],
  carouselComments: {
    title: "Nos étudiants nous aiment!",
    slides: [
      {
        img: "slider_comments_1.jpg",
        description:
          "Je suis venu en Russie du Kazakhstan, diplômé de l'Académie agricole d'État de Kuzbass, et je suis tellement heureux d'avoir passé mes années d'étudiant ici à Kuzbass! J'ai aimé la ville de Kemerovo à première vue, en particulier le boulevard sur lequel se trouve le campus. Au fil du temps, j'ai de plus en plus aimé la ville, et maintenant je ne veux plus la quitter. Au début de mes études, j'ai fait beaucoup de nouvelles connaissances, j'ai rencontré de nouveaux amis avec qui je communique encore. J'ai participé activement à des événements universitaires, j'ai étudié avec succès et, dans ma première année, je suis devenu membre d'un club étudiant, j'ai dansé lors de concerts universitaires. Puis, au cours des années suivantes, je me suis concentré sur la recherche et la formation continue en tant que traducteur.<br>",
        title: "Nika Sukhoterina, Kazakhstan",
      },
      {
        img: "slider_comments_2.jpg",
        description:
          "Bonjour à tous! Je m'appelle Erasyl Akmoldaev. Pour être honnête, je suis très heureux d'être entré à l'Académie agricole d'État de Kuzbass, car ici, je me suis avéré être une personne. Grâce à cette université, j'ai commencé à jouer au KVN (Club of Fun and Inventive), commencé à travailler en tant qu'organisateur d'événements, joué beaucoup et gagné à des concours créatifs. Je pense que j'ai de bonnes perspectives. En tant qu'étudiant étranger, je Je pensais que j'aurais des problèmes avec les études, avec la vie dans un nouveau pays, mais au cours des 5 ans, il n'y a pas eu un seul problème. Tous les Russes sont très sympathiques et serviables. Je ne regrette pas d'avoir choisi cette université, et je recommande à tous d'aller étudier à Kuzbass ! <br>",
        title: "Yerasyl Akmoldaev, Kazakhstan",
      },
      {
        img: "slider_comments_3.jpg",
        description:
          "Je suis étudiante en 4ème année à la Faculté de Management et <br> Agroalimentaire. Tout au long du processus de formation, j'ai <br> pris une position de vie active dans notre Académie. Je suis un <br> atout du club étudiant et l'été je travaille au comité de sélection, <br> je suis engagé dans l'orientation professionnelle. Notre <br> académie peut fournir non seulement une large sélection de <br> domaines d'études, mais aussi une éducation digne et la <br> possibilité de révéler vos capacités et de les développer. Nous <br> serons heureux de vous accueillir dans notre université <br>",
        title: "Ay-Kys Mongush, Russie",
      },
      {
        img: "slider_comments_4.jpg",
        description:
          "Maintenant je suis un étudiant en quatrième année. Je suis venu à Kuzbass du Kazakhstan. Au cours des années de mes études, de nombreux événements se sont produits dans ma vie. J'ai eu des succès sportifs, la première année où j'ai commencé l'athlétisme, j'ai été engagé pendant deux ans, j'ai participé à des compétitions en ville, il y a eu des victoires. Parfois, j'étais invité à jouer dans d'autres sports, par exemple, les compétitions de hockey étaient très mémorables! Avec les gars, nous avons décidé de créer notre propre équipe KVN, tout s'est très vite passé, nous avons immédiatement commencé à travailler, à jouer et à rejoindre rapidement la KVN Student League à Kemerovo. Notre équipe a atteint le ¼ de finale de la Ligue internationale de KVN Asie à Krasnoïarsk. Quand je me souviens de mes années étudiantes, je peux dire que mon premier succès était lié aux performances en KVN, et donc, maintenant je suis le capitaine de l'équipe Kazakhstar ! C'est le chemin que j'ai parcouru d'un assistant qui prend juste les accessoires à la scène, au chef d'équipe. Mon succès n'est pas seulement de la chance, c'est le résultat d'un travail acharné et de la confiance en moi, en mes capacités! <br>",
        title: "Raif Serebryakov, Kazakhstan",
      },
      {
        img: "slider_comments_5.jpg",
        description:
          'My name is Vladislav Miroshnikov, I graduated from Kuzbass State Agricultural Academy with diploma with high honours. I came from Kazakhstan and from the first year I started to be active in the academy. Since the 2nd year I have been the chairman of the trade union organization of students. I was awarded with letters of commendation and honorary diplomas of the Administration of the city of Kemerovo and the region, which helped me to obtain Russian citizenship. I took the 3rd place in the city contest "The best innovative project". For my personal contribution and development of the trade union movement in Kuzbass I was awarded the Anniversary Regional Medal.<br>And I want to thank the Academy, which helps students to develop scientifically and personally, there are several research laboratories with unique equipment, where you can conduct research and make discoveries. Academy lecturers are doctors of science, professors. I think that this aspect makes education qualitative and competitive! I wish everyone to enter our Academy, get education and make a successful career!<br>',
        title: "Vladislav Miroshnikov, Kazakhstan",
      },
      {
        img: "slider_comments_6.jpg",
        description:
          'I am a fourth year student of the engineering faculty of the field of education "Nature management and water use". During my studies at the Kuzbass State Agricultural Academy I am engaged in sports, there are all conditions for successful combination of active sports and studies.<br>In 2020 I became the owner of the belt of the MMA Open Cup of the City of Leninsk-Kuznetsky in professional combat - the battle for the belt in the weight category 77.1 kg.<br>I came to Russia from Tajikistan and I am very happy with the living conditions and I recommend to study at our Academy, there are a lot of students from my native country here.<br><br>',
        title: "Idibek Aliyev, Tajikistan",
      },
    ],
  },
};

// eslint-disable-next-line prettier/prettier
export const datatextfr = DataFr;
