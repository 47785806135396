<template>
  <div id="main-container-feestructure-block">
    <div class="block-title">
      <div class="title" v-html="container.title"></div>
    </div>
    <div class="block-description">
      <table border="1" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td>
              <p>
                <strong><em>Program</em></strong>
              </p>
            </td>
            <td>
              <p>
                <strong><em>Cost*</em></strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="light-text">
                Agricultural Engineer, Bachelor (four years)
              </p>
            </td>
            <td>
              <p>393,000 rub</p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="light-text">
                Veterinary Doctor, Specialty (five years)
              </p>
            </td>
            <td>
              <p>393,000 rub</p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="light-text">
                Economics and Management, Bachelor (four years)
              </p>
            </td>
            <td>
              <p>373,000 rub</p>
            </td>
          </tr>
          <!-- <tr>
            <td>
              <p class="light-text">Veterinary Doctor, Master&rsquo;s</p>
            </td>
            <td>
              <p>404,000 rub / $5,050</p>
            </td>
          </tr> -->
          <!-- <tr>
            <td>
              <p class="light-text">Economics and Management, Bachelor</p>
            </td>
            <td>
              <p>373,000 rub / $4,660</p>
            </td>
          </tr>
          <tr>
            <td>
              <p class="light-text">Economics and Management, Master&rsquo;s</p>
            </td>
            <td>
              <p>382,000 rub / $4,775</p>
            </td>
          </tr> -->
        </tbody>
      </table>

      <p>&nbsp;</p>

      <p>
        *The cost includes accommodation (comfort room in the hostel, 2-3
        people), meals (breakfast, lunch of three courses, dinner), branded
        clothing and transfer to remote buildings of the academy.
      </p>

      <p>
        The Academy provides educational programs with an option of training
        using distance learning technologies. For more detailed information,
        please, e-mail to <a href="mailto:inter@ksai.ru">inter@ksai.ru</a> or
        WhatsApp +7-909-522-80-77.
      </p>

      <p>&nbsp;</p>

      <p>
        Postgraduate programs are available in the following areas of Scientific
        Research:
      </p>

      <ul>
        <li>multifunctional feed additives</li>
        <li>robotics in the agricultural and industrial complex</li>
        <li>bioremediation</li>
        <li>genomic technologies in animal husbandry</li>
        <li>functional food products</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
// import { datatextfr } from "@/data/datatextfr.js";
// import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {} };
  },
  created() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.feeStructureBlock;
    }
    //     if (this.$route.name == "FrVersion") {
    //       this.container = datatextfr.feeStructureBlock;
    //     }
    //     if (this.$route.name == "AbVersion") {
    //       this.container = datatextab.feeStructureBlock;
    //     }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.block-title {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #dbefff;

  .title {
    font-weight: 700;
    font-size: 52px;
    line-height: 1.23;
    box-sizing: content-box;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
  }
}
.block-description {
  max-width: 1200px;
  display: table;
  margin: 0 auto;
  padding: 30px 20px;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 auto;
  opacity: 0.9;
  padding-bottom: 20px;
  font-weight: 300;
}
.light-text {
  font-weight: 300;
}
a {
  color: black;
}
table {
  // border-spacing: 90px 15px;
  margin: 0 auto;
  text-align: center;
  border: 4px double #dbefff;
  font-size: 18px;
  line-height: 50px;
  font-weight: 400;
  tr {
    border: 1px solid #dbefff;
  }
  tr:nth-child(2n + 1) {
    background: #f2f7ff; /* Цвет фона */
  }
  tr:nth-child(1) {
    background: #70abc7;
    color: #fff;
  }
  td {
    padding: 10px 30px;
  }
}
</style>
