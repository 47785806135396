<template>
  <div id="main-container-card-block">
    <!-- Карточка №1 -->
    <div class="block-title">
      <div class="title" v-html="container.block1.title"></div>
    </div>
    <!-- Карточка №1-1 -->
    <div
      class="block-card"
      v-for="(block, index) in container.block1.cards"
      :key="index"
    >
      <div class="block-card-container">
        <div class="block-card-wrapper">
          <div class="image-container">
            <img
              v-for="(img, index) in block.img"
              :key="index"
              :src="require('@/assets/' + img)"
            />
          </div>
          <div class="block-text block-card-color1">
            <div class="title" v-html="block.title"></div>
            <div class="description" v-html="block.description"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Карточка №2 -->
    <!-- <div class="block-title">
      <div class="title" v-html="container.block2.title"></div>
    </div> -->
    <!-- Карточка №2-1 -->
    <!-- <div
      class="block-card"
      v-for="(block, index) in container.block2.cards"
      :key="index"
    >
      <div class="block-card-container">
        <div class="block-card-wrapper">
          <div class="image-container">
            <img :src="require('@/assets/' + block.img)" />
          </div>
          <div class="block-text block-card-color2">
            <div class="title" v-html="block.title"></div>
            <div class="description" v-html="block.description"></div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Карточка №3 -->
    <!-- <div class="block-title">
      <div class="title" v-html="container.block3.title"></div>
    </div> -->
    <!-- Карточка №3-1 -->
    <div
      class="block-card"
      v-for="(block, index) in container.block3.cards"
      :key="index"
    >
      <div class="block-card-container">
        <div class="block-card-wrapper">
          <div class="image-container">
            <img :src="require('@/assets/' + block.img)" />
          </div>
          <div
            class="block-text block-card-color2"
            v-bind:class="{ 'block-card-color3': index == 1 }"
          >
            <div class="title" v-html="block.title"></div>
            <div class="description" v-html="block.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {} };
  },
  created() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.cardBlock;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.cardBlock;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.cardBlock;
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.block-card-color1 {
  background-color: #f2f7ff;
}
.block-card-color2 {
  background-color: #f2fbff;
}
.block-card-color3 {
  background-color: #ffe5e8;
}
.block-title {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #dbefff;

  .title {
    font-weight: 700;
    font-size: 52px;
    line-height: 1.23;
    box-sizing: content-box;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
  }
}
.block-card {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #ededed;
  .block-card-container {
    max-width: 1200px;
    margin: 0 auto;
    .block-card-wrapper {
      display: table;
      width: 100%;
      .image-container {
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        width: 50%;
        img {
          width: 100%;
          margin: 0;
          vertical-align: middle;
        }
      }
      .block-text {
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        width: 50%;
        .title {
          padding-top: 20px;
          text-align: center;
          max-width: 360px;
          padding-left: 10px;
          padding-right: 10px;
          margin: 0 auto;
          font-size: 28px;
          line-height: 1.23;
          font-weight: 600;
        }
        .description {
          padding-top: 20px;
          font-size: 16px;
          line-height: 25px;
          text-align: left;
          max-width: 520px;
          padding-left: 10px;
          padding-right: 10px;
          margin: 0 auto;
          opacity: 0.7;
          padding-bottom: 20px;
          font-weight: 300;
          strong {
            font-weight: 700;
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }
  }
}
</style>
