let DataEn = {
  contactsButton: {
    title: "Contact us!",
  },
  footer: {
    copyright: "© 2020 Kuzbass State Agricultural University",
    btn1: "University",
    btn2: "Courses",
    btn3: "Admission procedure",
    btn4: "Contacts",
    btn5: "Fee structure",
    backToTop: "Back to top",
  },
  contacts: {
    title: "+7 913 138 06 66<br>inter@ksai.ru<br>",
    description:
      '<strong data-v-75071f03=""><span data-v-75071f03="" style="font-size: 34px;"> Kuzbass State Agricultural University </span></strong><br data-v-75071f03=""><strong data-v-75071f03="">5 Markovtseva Street, Kemerovo,<br data-v-75071f03=""><strong data-v-75071f03="">Kemerovo</strong><strong data-v-75071f03="">region</strong> <strong data-v-75071f03="">- </strong><strong data-v-75071f03="">Kuzbass</strong><strong data-v-75071f03="">,</strong> Russia</strong><br data-v-75071f03="">',
  },
  feedback: {
    title: "Apply now",
    name: "Your name",
    course: "Course you want to study",
    phone: "Mobile phone number (Whats App, Viber)",
    email: "Your email",
    country: "Your Nationality",
    btnsubmit: "Send Application",
    successbox: "Thanks! Your request was sent! We will reply you soon!",
  },
  mainNavBar: {
    title: "Kuzbass State Agricultural University",
    btn1: "Courses",
    btn2: "Admission",
    btn3: "Contacts",
    btn4: "Fee structure",
  },
  lineBlock: {
    title: "Admission procedure",
    block: [
      {
        img: "1ed_list.svg",
        title: "Submit Application online",
        description:
          "Admission manager will contact you to choose the best option at relevant university. You will receive Admission Letter in 1-2 days.",
      },
      {
        img: "1ed_list.svg",
        title: "Sign Agreement and pay Registration fee",
        description:
          "Admission manager will send you Agreement and Invoice (includes Invitation cost and Admission charges). You can pay Online, via Bank transfer or by Western Union. You must sign Agreement and send PDF copy to us.",
      },
      {
        img: "kideducate_test.svg",
        title: "Wait for Official Invitation",
        description:
          "Invitation for study visa will be issued and send to you in PDF by email. Issuing time: 40 days.",
      },
      {
        img: "9ta_docs.svg",
        title: "Apply for a Student Visa",
        description:
          "Apply for a visa at nearest Russian Embassy. Legalize your Educational documents at Embassy. Pay Visa fee at Embassy.",
      },
      {
        img: "touragency_plane.svg",
        title: "Prepare your flight",
        description:
          "Buy your air ticket to Kuzbass and inform us about your arrival at least 3 days in advance.",
      },
      {
        img: "touragency_guide.svg",
        title: "Arrival",
        description:
          "We will inform Border Police about your arrival and our representative will meet you in airport and transfer you to University.",
      },
      {
        img: "1ed_teacher.svg",
        title: "Registration at University and final payment",
        description:
          "Register at University and transfer to/place in the dormitory. Pay Tuition fee, Accommodation fee, Health Insurance ($100/year) and Visa Extention ($30).",
      },
    ],
  },
  contactBlock: {
    btn1: "Contact by What's App",
    btn2: "Contact by Viber",
    btn3: "Email / Phone",
  },
  simpleText:
    '<strong></strong><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><strong><span style="font-size: 62px;" data-redactor-tag="span"><strong><strong data-redactor-tag="strong"></strong></strong></span></strong></strong></strong></span></strong></strong></span></strong></strong><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><span style="font-size: 62px;"><strong><strong data-redactor-tag="strong"><strong><span style="font-size: 62px;" data-redactor-tag="span"><strong><strong data-redactor-tag="strong"><span style="font-size: 18px;">Kuzbass State Agricultural University </span></strong></strong></span></strong></strong></strong></span></strong></strong></span></strong></strong>welcomes all foreign students. If you want to learn more about our education programs, admission procedure, or have other questions please contact us!<br><br><strong>Our admission officer is available for voice/video call and chat with you:</strong>',
  aboutContainer: {
    title: "<p>Kuzbass State Agricultural University</p>",
    description: `<p><strong>Welcome to the Kuzbass State Agricultural University &ndash; the unique Russian entrepreneurial university!</strong></p><br />
    
      <p style="text-align: center;"><strong>NOW – WITH ENGLISH MEDIUM</strong></p>
      <p>&nbsp;</p>
      <p><strong>BUSINESS ACTIVITY</strong></p>
      
      <p>For us, entrepreneurship is the science of life. Students learn by taking part in real research, community and business projects, so they have all possibilities to become leaders. Our graduates receive more than degrees, they get an opportunity to build a career at leading agricultural enterprises or establish their own businesses. The university has its own Farmer&rsquo;s School.</p>
      
      <p>&nbsp;</p>

      <p>Kuzbass State Agricultural University</p>
      
      <p>is a business and entrepreneurial type of university where students</p>
      
      <ul>
        <li>receive their pay during practices and internship while studying;</li>
        <li>take part in real scientific and practical projects;</li>
        <li>become leaders in various areas;</li>
        <li>create and implement public and community projects;</li>
        <li>by graduation, they receive not only degrees, but careers in the leading agricultural companies and their own business</li>
      </ul>
 
      <br />
      <p><strong>INNOVATIONS</strong></p>
      
      <p>Digital university, modern educational technologies, scientific and practical research for real life.</p>
      <br />
      <p><strong>ECOLOGY</strong></p>
      
      <p>The university promotes &lsquo;Green Volunteers&rsquo;, a volunteer environmental movement of students who participate in international nature conserving projects and events with branch offices in Russia and abroad.</p>
      
      <p>Our students take an active part in concervation of the environment within the framework of the regional standard &quot;Clean coal &ndash; Green Kuzbass&quot;. Professors and students of the university, with the support of business partners, plant more than 7,000 trees a year.</p>
      
      <p>The university is a regional operator of the All-Russian project &quot;Ecological Patrol&quot;.</p>
      <br />
      <p><strong>DESIGN</strong></p>
      
      <p>Art and design studio.</p>
      <br />
      <p><strong>HEALTHY LIFESTYLE and SPORT</strong></p>
      
      <p>The &lsquo;Healthy university&rsquo; canteen program. Sports club and an exclusive horse-riding club are some of the facilities offered to the students.</p>
      
      `,
  },
  cardBlock: {
    block1: {
      title: "<strong>Courses</strong>",
      cards: [
        {
          title: "AGRO-ENGINEERING",
          img: ["card8.jpg", "card9.jpg"],
          description: `<p>refers to professional profile &lsquo;Robotic Systems in the Agro-Industrial Complex&rsquo;</p>

          <p>(Bachelor degree &ndash; 4 years / Master&rsquo;s degree &ndash; 2 years of study)</p>
          
          <p>&nbsp;</p>
          
          <p>Agro-engineer is a manager, innovator and researcher in one person.</p>
          
          <p>The main task of an agricultural engineer is to ensure stable and efficient use of agricultural machinery and equipment at the enterprise.</p>
          
          <p>Career and future prospects</p>
          
          <p>Profession of an agricultural engineer offers a highly paid work in-demand as an agro-engineer, chief agricultural engineer of agricultural organizations, farms, manager in organizations specializing in the sale of agricultural machinery and equipment, agricultural consultant, teacher and specialist in agriculture in various state and municipal services; business development and participation in startup programs.</p>
          <p>&nbsp;</p>
          <p><strong>The uniqueness of the educational program</strong></p>
          
          <ul>
            <li>Students are encouraged to think outside the box. The program develops the skill of engineering invention, forms creative competence.</li>
            <li>The program provides a system that encourages the student&#39;s personal initiative and desire to deepen their knowledge in the field of digital agriculture and robotics.</li>
            <li>In each semester, students work on a team project, solve a real problem and apply scientific theory in practice.</li>
            <li>Students learn not only to manage, operate and maintain agro robots, but also to design and develop them.</li>
            <li>The program forms flexible competencies of the 21st century. Students become more enterprising and learn to apply their knowledge in the implementation of business and social projects.</li>
          </ul>
          
          <p>&nbsp;</p>`,
        },
        {
          title: "VETERINARY MEDICINE",
          img: ["card10.jpg", "card11.jpg"],
          description: `
            <p>refers to professional specialization &lsquo;Clinical veterinary medicine&rsquo;.</p>
            
            <p>(Specialty degree – 5 years of study)</p>
            <p>&nbsp;</p>
            <p>A veterinarian is a doctor engaged in the treatment and prevention of diseases of domestic, agricultural and commercial animals.</p>
            
            <p>The duties of a veterinarian include the prevention, diagnosis and treatment of diseases and traumatic injuries in all types of farm animals. A veterinary specialist prescribes medical treatment and performs surgical manipulations, monitors the health of pets during the treatment process. The doctor monitors the improvement of their productive qualities, veterinary control, forensic veterinary and veterinary-sanitary expertise.</p>
            
            <p>The main task of a veterinarian is to provide prevention, diagnosis of diseases of various etiologies and treatment of animals.</p>
            
            <p>&quot;A doctor treats a person, and a veterinarian treats humanity.&quot;</p>
            
            <p>Ivan Pavlov, Russian scientist and physiologist</p>
            
            <p>The uniqueness of the educational program</p>
            
            <ul>
              <li>During the program, students get acquainted with the peculiarities of anatomy and physiology of different animal species.</li>
              <li>In the classroom, the conditions of practical work of veterinary specialists in various situations are modeled, an interactive and case-based method of solving production tasks is used.</li>
              <li>Students have the opportunity to undergo paid internships and practical training in the laboratories of the university, agricultural enterprises and leading veterinary clinics.</li>
              <li>The program forms leadership qualities, communication skills that are necessary for a doctor when developing a strategy and solving complex tasks in conditions of uncertainty.</li>
            </ul>
            
            <p>&nbsp;</p>
            
            <p><strong>Career and future prospects </strong></p>
            <p>&nbsp;</p>
            <p>Profession of a veterinarian doctor offers work in veterinary hospitals, laboratories of research institutes and universities, livestock enterprises, animal farms, zoos, agri-food markets, catering establishments, customs, sanitary and epidemiological service and veterinary control bodies.</p>
            
            <p>The profession of a veterinarian doctor also involves an entrepreneurial path of development. You can open a private clinic, pharmacy, pet store; or just provide private consultations with home visits.</p>
            `,
        },
        {
          title: "ECONOMICS and MANAGEMENT",
          img: ["card12.jpg", "card13.jpg"],
          description: `<p>refers to professional profile &lsquo;Business administration&rsquo;.</p>

          <p>(Bachelor degree &ndash; 4 years / Master&rsquo;s degree &ndash; 2 years of study)</p>
          <p>&nbsp;</p>
          <p>Business management is a professional activity that includes a wide range of tasks: from choosing a goal and ways to achieve it to ensuring the necessary motivation of employees, accounting, analysis and evaluation of results. In order to become a specialist in this field, only talent and practice are not enough, systematic training in business management under the guidance of a highly qualified mentor and group project work is absolutely necessary.</p>
          
          <p>In the course of training, you will master a wide range of competencies in various functional areas of management, which will allow you to further build your career, moving both vertically and horizontally, periodically changing activities, which is a distinctive feature of highly qualified managers.</p>
          
          <p>Training with us will allow you to acquire the competencies necessary for both the business owner and the manager involved in the development of the company&#39;s strategy; a set of knowledge, skills and abilities that are sufficient for the independent organization of a profitable and viable business in modern market conditions.</p>
          <p>&nbsp;</p>
          <p><strong>Career and future prospects </strong></p>
          <p>&nbsp;</p>
          <p>Your future place of work may be:</p>
          
          <ul>
            <li>organizations of any organizational and legal form (commercial, non-commercial, state, municipal);</li>
            <li>structures in which graduates are entrepreneurs who create and develop their own business;</li>
            <li>structures, foundations, associations cooperating with individual enterprises</li>
          </ul>
          
          <p><strong>You will be able to work</strong> in the following positions: commercial director, Business development director, director, individual entrepreneur.</p>
          
          <p><strong>You will learn</strong> how to found a company and plan its activities, assemble a team that will bring results, manage personnel, marketing and finance, innovations and technological processes, build a quality management system, assess risks and efficiency.</p>
          
          <p><strong>You will be able</strong> to develop and implement a company strategy that will achieve the desired results; write a business plan, develop a project, build company processes and become a successful entrepreneur.</p>
          `,
        },
        // {
        //   title: "Pedagogy",
        //   img: ["card8.jpg", "card9.jpg"],
        //   description:
        //     '<strong>Duration: 4 years <br>Tuition fee: $1,850 / year</strong><strong><br><br></strong>Educational program specialization "Biology" <br><br> A teacher is a profession of the future. After this program you will be a mentor, a specialist, whose aim is to educate children and young people. <br>',
        // },
      ],
    },
    // block2: {
    //   title: "Master's degree program",
    //   cards: [
    //     {
    //       title: "Technological Entrepreneurship",
    //       img: "card5.jpg",
    //       description:
    //         '<strong data-v-6130c4dd="">Duration: 2 years<br data-v-6130c4dd="">Tuition fee: $2,400 / year</strong><br data-v-6130c4dd=""><br data-v-6130c4dd="">Educational program specializations "Agronomy" and "Zootechnics" <br data-v-6130c4dd=""><br data-v-6130c4dd=""> Creation of a high-tech business in order to promote innovation based on fundamental research in crop and livestock production. <br data-v-6130c4dd="">',
    //     },
    //   ],
    // },
    block3: {
      title: "Specialist's degree program",
      cards: [
        // {
        //   title: "Ground transport and technical facilities",
        //   img: "card6.jpg",
        //   description:
        //     '<strong>Duration: 4,8 years<br>Tuition fee: $1,850 / year</strong><br><br>Educational program specialization "Technical facilities of AIC" <br><br> Engineer is a manager and an innovator. During training you will be involved in the design, production and operation of cars, trucks and other land vehicles. <br><strong></strong>',
        // },
        {
          title:
            "Russian language course<br>Pre-University Programme <br><br>Duration 9 month<br>Tuition fee $1500",
          img: "card7.jpg",
          description:
            "This programme is designed for those who wish to enter one of Russian University or to prepare themselves for undergraduate or postgraduate studies. The programme includes: <br> <ul> <li> General Russian Language Course; </li> <li> Russian for Academic Purposes;</li></ul> ",
        },
      ],
    },
  },
  feeStructureBlock: {
    title: "Fee Structure for international students, per year",
    description: ``,
  },
  simpleTextVideos: {
    title: "More video:",
    li: [
      { text: "Agronomy ", url: "https://youtu.be/YZGLmdri894" },
      { text: "Management ", url: "https://youtu.be/51GXVjeHsIY" },
      { text: "Zootechnics ", url: "https://youtu.be/QLjpQTc8Ptg" },
      { text: "«Green Volunteers» ", url: "https://youtu.be/v6QFFuDRcfY" },
    ],
  },
  simpleTableText: [
    "Welcoming atmosphere and comfortable conditions for life, <br> study and business <br>",
    "Digital university has modern laboratories and long distance <br> technologies <br>",
    "Real business projects and successful agribusiness career<br>",
    "<strong>Sport, healthy lifestyle, ecology, culture, leadership, volunteering <br> activities <br></strong>",
    "<strong>Life sciences researches</strong>",
  ],
  carouselBanner: {
    slides: [
      {
        img: "slider_banner_1.jpg",
        title: "We create the bright future of agribusiness!",
        toptitle: "",
        subtitle: "Language of study: Russian, English",
      },
      {
        img: "slider_banner_2.jpg",
        title: "Kuzbass State Agricultural University",
        toptitle: "Study in Russia",
        subtitle: "Language of study: Russian, English",
      },
      {
        img: "slider_banner_3.jpg",
        title: "Entrepreneurial university",
        toptitle: "Study in Russia",
        subtitle: "Language of study: Russian, English",
      },
    ],
  },
  carouselGallery: [
    "slider_galery_1.jpg",
    "slider_galery_2.jpg",
    "slider_galery_3.jpg",
    "slider_galery_4.jpg",
    "slider_galery_5.jpg",
    "slider_galery_6.jpg",
    "slider_galery_7.jpg",
    "slider_galery_8.jpg",
    "slider_galery_9.jpg",
    "slider_galery_10.jpg",
    "slider_galery_11.jpg",
    "slider_galery_12.jpg",
    "slider_galery_13.jpg",
    "slider_galery_14.jpg",
    "slider_galery_15.jpg",
    "slider_galery_16.jpg",
    "slider_galery_17.jpg",
    "slider_galery_18.jpg",
    "slider_galery_19.jpg",
    "slider_galery_20.jpg",
    "slider_galery_21.jpg",
    "slider_galery_22.jpg",
    "slider_galery_23.jpg",
    "slider_galery_24.jpg",
    "slider_galery_25.jpg",
    "slider_galery_26.jpg",
    "slider_galery_27.jpg",
  ],
  carouselComments: {
    title: "Our students love us",
    slides: [
      {
        img: "slider_comments_1.jpg",
        description:
          "I came to Russia from Kazakhstan, graduated from the Kuzbass State" +
          "Agricultural University and I am so glad that I spent my student years" +
          "here in Kuzbass! I liked Kemerovo city at first sight, especially" +
          "the Boulevard on which the campus is located. After a while I liked" +
          "the city more and more, and now I do not want to leave it at all.<br />At" +
          "the beginning of my studies I made a lot of acquaintances, I met new" +
          "friends with whom I communicate to this day. I started to show" +
          "myself actively at the university concerts, in addition to" +
          "successful studies in the first year of my studies I was a member of" +
          "the student club, I danced at the university event. Then in the" +
          "following years, I focused on scientific activities and obtaining" +
          "additional education as an interpreter.<br />",
        title: "Nika Sukhoterina, Kazakhstan",
      },
      {
        img: "slider_comments_2.jpg",
        description:
          "Hello, everybody! My name is Yerasyl Akmoldayev. To be honest, I am very glad" +
          "that I entered the Kuzbass State Agricultural University, because here I revealed" +
          "myself as a person. Thanks to this university I started to play in KVN (Club" +
          "of the cheerful and resourceful people), began to work as a host of events," +
          "performed a lot and won at creative competitions.<br />I think I have good" +
          "prospects.<br />As an international student I thought that I would have" +
          "problems with studying, with living, but for these 5 years there was not a" +
          "single problem.<br />All Russians are very friendly and sympathetic. I do not" +
          "regret at all that I chose this university and I recommend everyone to go to" +
          "study in Kuzbass!<br />",
        title: "Yerasyl Аkmoldayev, Kazakhstan",
      },
      {
        img: "slider_comments_3.jpg",
        description:
          "I am a 4<sup>th</sup> year student of the faculty of &quot;Management and" +
          "Agribusiness&quot;. <br />" +
          "Throughout the educational process I have been taking an active life position" +
          "in our University. I am an asset of the student club and in summer I work in the" +
          "Admissions Committee, I am engaged in career guidance activities. Our University " +
          "can not only provide a wide range of professions, but also a decent education" +
          "and the opportunity to discover their existing abilities and get new ones. We" +
          "will be glad to welcome you in our University! <br />",
        title: "Ai-Kys Mongush, Russia",
      },
      {
        img: "slider_comments_4.jpg",
        description:
          "I'm a 4<sup>th</sup> year student now. I came to Kuzbass from" +
          "Kazakhstan. For years of study in my life there were many events." +
          "<br />" +
          "I had successes in sports, in the first year I began to do athletics," +
          "was engaged two years, participated in city competitions, there were" +
          "victories. Sometimes I was invited to perform in other sports, for" +
          "example, very memorable valenki hockey! With the guys we decided to" +
          "create our own KVN team, everything turned out very quickly, we" +
          "immediately began to work, play in Kuzbass SAA, and soon joined the" +
          "KVN Students League of Kemerovo. Our team reached ¼ final of the" +
          "International KVN League of Asia in Krasnoyarsk. <br />" +
          "When I remember my student years, I can say that my first success was" +
          "due to performances in KVN, because now I am the captain of the team" +
          '"Kazakhstar"! This is the way I went - from arranging chairs on stage' +
          "to managing the team.Success is not just luck, it's the result of hard" +
          "work and faith in yourself, in your abilities! <br /><br />",
        title: "Raif Serebryakov, Kazakhstan",
      },
      {
        img: "slider_comments_5.jpg",
        description:
          "My name is Vladislav Miroshnikov, I graduated from Kuzbass State" +
          "Agricultural University with diploma with high honours. I came from" +
          "Kazakhstan and from the first year I started to be active in the" +
          "university. Since the 2<sup>nd</sup> year I have been the chairman of the" +
          "trade union organization of students. I was awarded with letters of" +
          "commendation and honorary diplomas of the Administration of the city" +
          "of Kemerovo and the region, which helped me to obtain Russian" +
          'citizenship. I took the 3<sup>rd</sup> place in the city contest "The' +
          'best innovative project". For my personal contribution and development' +
          "of the trade union movement in Kuzbass I was awarded the Anniversary" +
          "Regional Medal.<br />" +
          "And I want to thank the University, which helps students to develop" +
          "scientifically and personally, there are several research laboratories" +
          "with unique equipment, where you can conduct research and make" +
          "discoveries. University lecturers are doctors of science, professors. I" +
          "think that this aspect makes education qualitative and competitive! I" +
          "wish everyone to enter our University, get education and make a" +
          "successful career! <br /><br /><br />",
        title: "Vladislav Miroshnikov, Kazakhstan",
      },
      {
        img: "slider_comments_6.jpg",
        description:
          "I am a fourth year student of the engineering faculty of the field of" +
          'education "Nature management and water use". During my studies at the' +
          "Kuzbass State Agricultural University I am engaged in sports, there are" +
          "all conditions for successful combination of active sports and" +
          "studies. <br />In 2020 I became the owner of the belt of the MMA Open" +
          "Cup of the City of Leninsk-Kuznetsky in professional combat - the" +
          "battle for the belt in the weight category 77.1 kg. <br />I came to" +
          "Russia from Tajikistan and I am very happy with the living conditions" +
          "and I recommend to study at our University, there are a lot of students" +
          "from my native country here.<br /><br />",
        title: "Idibek Aliyev, Tajikistan",
      },
    ],
  },
};

export const datatexten = DataEn;
// eslint-disable-next-line prettier/prettier
