<template>
  <transition :name="direction" mode="in-out">
    <div
      :id="'slide' + index"
      class="carousel-slide"
      v-show="visibleSlide === index"
    >
      <img
        :id="'img' + index"
        :src="require('@/assets/' + slide.img)"
        class="img"
      />
      <div
        :id="'table' + index"
        class="carousel-slide-table"
        :style="{
          height: getHeight(),
        }"
      >
        <div class="carousel-slide-text">
          <div class="toptitle" v-html="slide.toptitle"></div>
          <div class="title" v-html="slide.title"></div>
          <div class="subtitle" v-html="slide.subtitle"></div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ["slide", "index", "visibleSlide", "direction"],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    getHeight() {
      let cbmHeight = document.getElementById(
        "carousel-banner-main"
      ).clientHeight;
      if (cbmHeight > 0) {
        return cbmHeight + "px";
      } else {
        return (document.documentElement.clientWidth / 1493) * 840 + "px";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel-slide {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.carousel-slide-table {
  display: table;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.carousel-slide-text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  color: white;
}
.toptitle {
  font-size: 20px;
  max-width: 920px;
  margin: 0 auto;
  font-weight: 600;
}
.title {
  font-weight: 700;
  font-size: 68px;
  max-width: 920px;
  margin: 0 auto;
}
.subtitle {
  max-width: 920px;
  margin: 0 auto;
  margin-top: 30px;
  line-height: 1.55;
  font-size: 20px;
  font-weight: 300;
}
.img {
  position: relative;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  display: block;
  float: left;
  box-sizing: border-box;
  filter: brightness(75%);
}
.left-enter-active {
  animation: leftInAnimation 0.6s ease-in-out;
}
.left-leave-active {
  animation: leftOutAnimation 0.6s ease-in-out;
}
@keyframes leftInAnimation {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes leftOutAnimation {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-100%);
  }
}
.right-enter-active {
  animation: rightInAnimation 0.6s ease-in-out;
}
.right-leave-active {
  animation: rightOutAnimation 0.6s ease-in-out;
}
@keyframes rightInAnimation {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes rightOutAnimation {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(+100%);
  }
}
</style>
