<template>
  <div id="carousel-main">
    <div class="main-title" v-html="container.title"></div>
    <div id="carousel">
      <div
        v-for="(slide, index) in container.slides"
        :key="slide"
        class="slide-wrapper"
      >
        <carousel-comments-slide
          :slide="slide"
          :index="index"
          :visibleSlide="visibleSlide"
          :direction="direction"
        />
      </div>
      <div class="button-wrapper">
        <button @click="next" class="next"></button>
        <button @click="prev" class="prev"></button>
      </div>
    </div>
    <div class="bullet-wrapper">
      <div
        class="bullet"
        v-for="(slide, index) in container.slides"
        :key="index"
      >
        <div
          class="bullet-body"
          :class="{ active: index === visibleSlide }"
          @click="
            index < visibleSlide ? (direction = 'right') : (direction = 'left');
            visibleSlide = index;
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
import CarouselCommentsSlide from "@/components/CarouselCommentsSlide.vue";
export default {
  components: { CarouselCommentsSlide },
  data() {
    return {
      container: {},
      visibleSlide: 0,
      direction: "left",
      // height: 0,
      // maxlength: 0,
      // maxlengthindex: 0,
    };
  },
  computed: {
    slidesLen() {
      return this.container.slides.length;
    },
  },
  created() {},
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.carouselComments;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.carouselComments;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.carouselComments;
    }

    // this.slides.forEach((element, index) => {
    //   if (element.description.length > this.maxlength) {
    //     this.maxlength = element.description.length;
    //     this.maxlengthindex = index;
    //   }
    // });
  },
  methods: {
    // slideHeight(height) {
    //   this.height = height;
    //   document.getElementById("carousel").style.height = height + "px";
    // },
    next() {
      if (this.visibleSlide >= this.slidesLen - 1) {
        this.visibleSlide = 0;
      } else {
        this.visibleSlide++;
      }
      this.direction = "left";
      // this.slideHeight(
      //   document.getElementById("slide" + this.visibleSlide).clientHeight
      // );
    },
    prev() {
      if (this.visibleSlide <= 0) {
        this.visibleSlide = this.slidesLen - 1;
      } else {
        this.visibleSlide--;
      }
      this.direction = "right";
      // this.slideHeight(
      //   document.getElementById("slide" + this.visibleSlide).clientHeight
      // );
    },
  },
};
</script>
<style lang="scss" scoped>
#carousel-main {
  padding-top: 45px;
  padding-bottom: 45px;
}
#carousel {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;
}
.main-title {
  margin-bottom: 90px;
  text-align: center;
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
  margin-left: 20px;
  margin-right: 20px;
}

button {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(232, 232, 232, 1);
  top: calc(50% - 15px);
  border-radius: 100px;
  border: none;
  display: block;
  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
  }
}
.button-wrapper {
  display: block;
}

.next {
  right: 0;
  &::after {
    content: ">";
  }
}
.prev {
  left: 0;
  &::after {
    content: "<";
  }
}
.bullet-wrapper {
  text-align: center;
  position: relative;
  z-index: 15;
  padding: 16px 0 0;
  width: 100%;
  font-size: 0;
}
.bullet {
  display: inline-block;
  padding: 10px 5px;
  cursor: pointer;
}
.bullet-body {
  width: 8px;
  height: 8px;
  background-color: #c7c7c7;
  border-radius: 100px;
  &:hover,
  &.active {
    background-color: #222 !important;
  }
}
</style>
