<template>
  <div id="simple-table-text-main">
    <div id="title">
      Why study at<br />
      Kuzbass State Agricultural Academy
    </div>
    <div class="table-container">
      <template v-for="(text, index) in container" :key="index">
        <div v-if="index == 3" class="separator"></div>
        <div class="table-cell">
          <div class="table-cell-container">
            <img src="@/assets/ok.svg" />
            <div class="text" v-html="text"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {} };
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.simpleTableText;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.simpleTableText;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.simpleTableText;
    }
  },
};
</script>
<style lang="scss" scoped>
#simple-table-text-main {
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
  max-width: 1200px;
  #title {
    margin-bottom: 105px;
    text-align: center;
    font-size: 42px;
    line-height: 1.23;
    font-weight: 700;
  }
  .table-container {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: 100%;
    max-width: 1200px;
    display: table;
  }
  .table-cell {
    display: inline;
    float: left;
    margin: 20px;
    width: 100%;
    max-width: 360px;
  }
  .table-cell-container {
    display: table;
  }
  img {
    display: table-cell;
    vertical-align: middle;
    margin-right: 16px;
    width: 35px;
    height: auto;
  }
  .text {
    display: table-cell;
    vertical-align: middle;
    margin-top: 23px;
    font-size: 18px;
    line-height: 1.35;
    font-weight: 600;
  }
  .separator {
    margin-bottom: 30px;
    clear: both;
  }
}
</style>
