<template>
  <div id="carousel-gallery-main">
    <div id="carousel-gallery" :style="{ width: slideWidth }">
      <div v-for="(slide, index) in slides" :key="slide" class="slide-wrapper">
        <carousel-gallery-slide
          :slide="slide"
          :index="index"
          :visibleSlide="visibleSlide"
          :direction="direction"
        />
      </div>
    </div>
    <div class="button-wrapper">
      <button @click="next" class="next"></button>
      <button @click="prev" class="prev"></button>
    </div>
    <div class="bullet-wrapper">
      <div class="bullet" v-for="(slide, index) in slides" :key="index">
        <div
          class="bullet-body"
          v-if="index > 0 && index < slidesLen - 1"
          :class="{ active: index === visibleSlide }"
          @click="
            index < visibleSlide ? (direction = 'right') : (direction = 'left');
            visibleSlide = index;
            shift();
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import CarouselGallerySlide from "@/components/CarouselGallerySlide.vue";
export default {
  components: { CarouselGallerySlide },
  data() {
    return {
      slides: {},
      visibleSlide: 1,
      direction: "left",
      centercoef: 0,
      // height: 0,
      // maxlength: 0,
      // maxlengthindex: 0,
    };
  },
  computed: {
    slidesLen() {
      return this.slides.length;
    },
    slideWidth() {
      return this.slidesLen * 900 + "px";
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.centercoef = (2700 - document.documentElement.clientWidth) / 2;
    document.getElementById("carousel-gallery").style.left =
      "-" + (this.visibleSlide - 1) * 900 - this.centercoef + "px";

    this.slides = datatexten.carouselGallery;
    this.slides.unshift(this.slides[this.slides.length - 1]);
    this.slides.push(this.slides[1]);

    //     this.slides.forEach((element, index) => {
    //       if (element.description.length > this.maxlength) {
    //         this.maxlength = element.description.length;
    //         this.maxlengthindex = index;
    //       }
    //     });
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.centercoef = (2700 - document.documentElement.clientWidth) / 2;
      document.getElementById("carousel-gallery").style.left =
        "-" + (this.visibleSlide - 1) * 900 - this.centercoef + "px";
    },
    shift() {
      document.getElementById("carousel-gallery").style.left =
        (this.visibleSlide - 1) * -900 - this.centercoef + "px";
    },
    next() {
      if (this.visibleSlide >= this.slidesLen - 2) {
        this.visibleSlide = 1;
      } else {
        this.visibleSlide++;
      }
      this.shift();
      // this.slideHeight(
      //   document.getElementById("slide" + this.visibleSlide).clientHeight
      // );
    },
    prev() {
      if (this.visibleSlide <= 1) {
        this.visibleSlide = this.slides.length - 2;
      } else {
        this.visibleSlide--;
      }
      this.shift();
      // this.slideHeight(
      //   document.getElementById("slide" + this.visibleSlide).clientHeight
      // );
    },
  },
};
</script>
<style lang="scss" scoped>
#carousel-gallery-main {
  padding-top: 45px;
  padding-bottom: 45px;
  max-width: 2700px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}
#carousel-gallery {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  transition: all 0.6s ease-out;
}
.button-wrapper {
  display: block;
}
button {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgba(232, 232, 232, 1);
  top: calc(50% - 15px);
  border-radius: 100px;
  border: none;
  display: block;
  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
  }
}
.next {
  right: 0;
  left: calc(50% + 435px);
  &::after {
    content: ">";
  }
}
.prev {
  left: 0;
  left: calc(50% - 465px);
  &::after {
    content: "<";
  }
}
.bullet-wrapper {
  text-align: center;
  position: relative;
  z-index: 15;
  padding: 16px 0 0;
  width: 100%;
  font-size: 0;
}
.bullet {
  display: inline-block;
  padding: 10px 5px;
  cursor: pointer;
}
.bullet-body {
  width: 8px;
  height: 8px;
  background-color: #c7c7c7;
  border-radius: 100px;
  &:hover,
  &.active {
    background-color: #222 !important;
  }
}
</style>
