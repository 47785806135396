<template>
  <div id="about-main-container">
    <div id="image-container">
      <img src="@/assets/about1.jpg" />
      <img src="@/assets/about2.jpg" />
      <img src="@/assets/about3.jpg" />
    </div>
    <div id="text-container">
      <div id="text-table">
        <div id="text-cell">
          <div id="title-container" v-html="container.title"></div>
          <div id="line"></div>
          <div
            id="description-container"
            v-html="container.description"
            v-bind:class="{ right: isRight }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: [], isRight: false };
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.aboutContainer;
      this.isRight = false;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.aboutContainer;
      this.isRight = false;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.aboutContainer;
      this.isRight = true;
    }
  },
};
</script>
<style lang="scss" scoped>
#about-main-container {
  .right {
    text-align: center !important;
  }
  max-width: 1200px;
  display: table;
  margin: 0 auto;
  padding: 30px 20px;
  #image-container {
    width: 50%;
    min-width: 350px;
    display: table-cell;
    vertical-align: middle;
    img {
      width: 100%;
    }
  }
  #text-container {
    float: left;
    min-width: 350px;
    box-sizing: border-box;
    padding-left: 40px;
    #text-table {
      display: table;
      height: 69.8vh;
    }
    #text-cell {
      display: table-cell;
      vertical-align: middle;
    }
    #title-container {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 16px;
      line-height: 1.23;
    }
    #line {
      background-color: #259da8;
      max-width: 50px;
      width: 100%;
      height: 3px;
      display: block;
      margin: 0 auto;
    }
    #description-container {
      margin-top: 28px;
      font-size: 18px;
      text-align: left;
      line-height: 1.55;
      font-weight: 300;

      strong {
        font-weight: 700;
      }
    }
  }
}
</style>
