<template>
  <div id="footer-main">
    <div id="t-footer">
      <div id="t-maincontainer">
        <div id="content">
          <div class="signature">
            <div
              class="copyright"
              field="text"
              v-html="container.copyright"
            ></div>
          </div>
          <div class="center-block">
            <div class="centercontainer">
              <ul>
                <li>
                  <a
                    class="typo"
                    @click="scrolltoelem('about-main-container')"
                    >{{ container.btn1 }}</a
                  >
                </li>
                <li>
                  <a
                    class="typo"
                    @click="scrolltoelem('main-container-feestructure-block')"
                    >{{ container.btn5 }}</a
                  >
                </li>
                <li>
                  <a
                    class="typo"
                    @click="scrolltoelem('main-container-card-block')"
                    >{{ container.btn2 }}</a
                  >
                </li>
                <li>
                  <a class="typo" @click="scrolltoelem('line-block-main')">{{
                    container.btn3
                  }}</a>
                </li>
                <li>
                  <a class="typo" @click="scrolltoelem('contacts-main')">{{
                    container.btn4
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="scroll-right">
            <div class="scroll">
              <a class="text" @click="scrolltotop()">
                {{ container.backToTop }}
                <span class="icon">
                  <svg width="5" height="17" viewBox="0 0 6 20">
                    <path
                      fill="#ffffff"
                      d="M5.78 3.85L3.12.28c-.14-.14-.3-.14-.43 0L.03 3.85c-.14.13-.08.27.13.27h1.72V20h2.06V4.12h1.72c.15 0 .22-.07.19-.17a.26.26 0 00-.07-.1z"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datatexten } from "@/data/datatext.js";
import { datatextfr } from "@/data/datatextfr.js";
import { datatextab } from "@/data/datatextab.js";
export default {
  data() {
    return { container: {} };
  },
  mounted() {
    if (this.$route.name == "EnVersion") {
      this.container = datatexten.footer;
    }
    if (this.$route.name == "FrVersion") {
      this.container = datatextfr.footer;
    }
    if (this.$route.name == "AbVersion") {
      this.container = datatextab.footer;
    }
  },
  methods: {
    scrolltotop() {
      document.getElementById("navbar").scrollIntoView({ behavior: "smooth" });
    },
    scrolltoelem(elem) {
      document.getElementById(elem).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss" scoped>
#footer-main {
  padding-bottom: 15px;
  background-color: #111111;
  #t-footer {
    overflow: hidden;
    #t-maincontainer {
      width: 100%;
      height: 70px;
      display: table;
      #content {
        padding: 2px 40px 0;
        display: table-cell;
        vertical-align: middle;
        .signature {
          display: table-cell;
          vertical-align: middle;
        }
        .copyright {
          color: #ffffff;
          font-size: 14px;
          width: 250px;
          line-height: 1.35;
          font-weight: 600;
        }
        .center-block {
          width: 100%;
          padding: 0 16px;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }
        .centercontainer {
          ul {
            list-style-type: none;
            margin: 0;
            padding-left: 0 !important;
            margin-bottom: 0 !important;
          }
          li {
            display: inline-block;
            margin: 0 15px;
            font-size: 16px;
            line-height: 1.35;
            font-weight: 600;
            box-sizing: content-box;
          }
          a {
            color: #ffffff;
            text-decoration: none;
            font-size: 14px;
            line-height: 1.35;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .scroll-right {
          display: table-cell;
          vertical-align: middle;
        }
        .scroll {
          width: 250px;
          text-align: right;
          margin: 0;
          .text {
            color: #ffffff;
            text-decoration: none;
            position: relative;
            padding-right: 12px;
            display: inline-block;
            line-height: 1.35;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
          }
          .icon {
            position: absolute;
            right: 0;
            top: 2px;
            color: #ffffff;
            font-size: 14px;
            line-height: 1.35;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
