<template>
  <div>
    <navbar />
  </div>
</template>
<script>
import Navbar from "@/components/MainNavbar.vue";
export default {
  name: "main-layout",
  components: { Navbar },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
html,
body,
a,
b,
blockquote,
center,
code,
div,
h1,
h2,
h3,
h4,
h5,
h6,
i,
img,
p,
pre,
span,
table,
td,
th,
tr,
u,
video {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
